import { Button as BasicButton, ButtonProps as BasicButtonProps, Spinner } from 'react-bootstrap';
import { FormuxDataContainer } from './FormuxDataContainer';

import { cn } from '@utils/general';
import { ButtonVariant } from './types';

export interface ButtonProps extends BasicButtonProps {
  label?: string;
  isBusy?: boolean;
  formuxSubmit?: boolean;
  variant?: ButtonVariant;
}

export const Button = ({
  label,
  className,
  disabled,
  isBusy,
  formuxSubmit,
  onClick,
  ...omittedProps
}: ButtonProps) => {
  if (formuxSubmit) {
    return (
      <FormuxDataContainer>
        {({ hasChange, isValid, errorMode, setErrorMode }) => {
          const buttonDisabled = disabled || !isValid || !hasChange;

          return (
            <BasicButton
              variant="primary"
              disabled={disabled || isBusy}
              className={cn(
                'position-relative',
                {
                  disabled: isBusy
                },
                className
              )}
              {...omittedProps}
              onClick={(e) => {
                /**
                 * Cuando se monta el formulario el mode de error es 'touched' mostrando los errores de validacion en los fields que has sido "tocados"
                 * Pero una vez hecho click en el boton de submit se mustran los restantes erres aunque no hayan sido tocados los fields
                 */
                if (errorMode === 'touched') {
                  setErrorMode('all');
                }

                if (buttonDisabled) {
                  return;
                }

                onClick?.(e);
              }}
            >
              {label}
              {isBusy && (
                <div className="d-flex justify-content-center align-items-center position-absolute start-0 top-0 end-0 bottom-0">
                  <Spinner animation="border" role="status" size="sm" />
                </div>
              )}
            </BasicButton>
          );
        }}
      </FormuxDataContainer>
    );
  }

  return (
    <BasicButton
      variant="primary"
      disabled={disabled || isBusy}
      className={cn(
        'position-relative',
        {
          disabled: isBusy
        },
        className
      )}
      onClick={onClick}
      {...omittedProps}
    >
      {label}
      {isBusy && (
        <div className="d-flex justify-content-center align-items-center position-absolute start-0 top-0 end-0 bottom-0">
          <Spinner animation="border" role="status" size="sm" />
        </div>
      )}
    </BasicButton>
  );
};
