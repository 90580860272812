import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { VendorSelectionModal } from '../vendor-select-modal';

import { useGetComponentTypes } from '@api/component/useGetComponentTypes';
import { useGetVendors } from '@api/vendor/useGetVendors';

export interface AddComponentProps {
  component: any;
  onClose: () => void;
}

export const AddComponent = ({ component, onClose }: AddComponentProps) => {
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [vendorsNames, setVendorsNames] = useState<Array<string>>([]);
  const [selectedVendorName, setSelectedVendorName] = useState<string>();

  const { getComponentTypes } = useGetComponentTypes();
  const componentTypes = getComponentTypes.data?.result || [];

  useEffect(() => {
    getComponentTypes.fetch();
  }, []);

  const { getVendors } = useGetVendors();

  const handleSetVendor = async () => {
    getVendors.fetch(undefined, {
      onAfterSuccess: ({ result }) => {
        setVendorsNames(result.map(({name}) => name));
        setShowVendorModal(true);
      }
    });
  };


  return (
    <div className="custom-popup-overlay">
      <div className="custom-popup-content p-4">
        <button className="custom-close-button" onClick={onClose}>
          &times;
        </button>
        <h2 className="custom-text-center custom-font-weight-bold">Components</h2>
        <p className="custom-text-center">Please modify the component</p>

        <Form>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Select the type of component</Form.Label>
                <div className="custom-select-wrapper">
                  <Form.Control as="select" defaultValue={component?.type || 'Hybrid Inverter'}>
                    {componentTypes.map((typeObj, index) => (
                      <option key={index} value={typeObj.type}>
                        {typeObj.type}
                      </option>
                    ))}
                  </Form.Control>
                </div>
              </Form.Group>
              <Button variant="outline-success">Select datasheet ...</Button>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Brand</Form.Label>
                <Form.Control type="text" defaultValue={component?.brand || ''} />
              </Form.Group>
              <Button variant="outline-success">Select image ...</Button>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Model</Form.Label>
                <Form.Control type="text" defaultValue={component?.model || ''} />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group>
                <Form.Label>Vendors</Form.Label>
                <Form.Control
                  as="select"
                  multiple
                  value={selectedVendorName ? [selectedVendorName] : []}
                  className="custom-vendor-select"
                >
                  {vendorsNames.map((vendorName, index) => (
                    <option key={index} value={vendorName}>
                      {vendorName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Button
                variant="primary"
                className="custom-set-vendor-btn mr-2 mx-2"
                onClick={handleSetVendor}
              >
                Set vendor
              </Button>
              <Button variant="danger" className="custom-remove-vendor-btn">
                Remove vendor
              </Button>
            </Col>
          </Row>

          <div className="custom-highlight-box p-3 mt-3">
            <p>This area will show some controls depending on the selected component type.</p>
          </div>

          <div className="custom-text-center mt-4">
            <Button variant="primary" className="px-5">
              Save
            </Button>
          </div>
        </Form>

        <VendorSelectionModal
          show={showVendorModal}
          onHide={() => setShowVendorModal(false)}
          vendors={vendorsNames}
          onSelectVendor={setSelectedVendorName}
        />
      </div>
    </div>
  );
};
