import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { SolarComponent } from '@app-types/general';
import { getEndpoint } from '@utils/api';

export const useGetDesignComponents = (): {
  getDesignComponents: FetchResource<{ designId: number }, Array<SolarComponent>>;
} => {
  const fetch = useFetch<Array<SolarComponent>>();

  return {
    getDesignComponents: {
      data: fetch[0],
      status: fetch[1],
      fetch: ({ designId }, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({
              path: '/design/get-design-components',
              query: { designId }
            })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
