import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';

import { Header } from '@component/header';

import { AddComponent } from './components/add-component';
import { AddDesign } from './components/add-design';
import { ComponentDetails } from './components/component-details';
import { ComponentPopup } from './components/component-popup';
import { DesignPopup } from './components/design-popup';
import { GridTiedPopup } from './components/grid-tied-popup';

import './index.css';
import { useGetComponentDetails } from '@api/component/useGetComponentDetails';
import { useGetComponents } from '@api/component/useGetComponents';
import { useGetDesignComponents } from '@api/design/useGetDesignComponents';
import { useGetDesignVersions } from '@api/design/useGetDesignVersions';
import { useGetSolarDesigns } from '@api/design/useGetSolarDesigns';
import { useAddVendors } from '@api/vendor/useAddVendors';
import { useGetVendors } from '@api/vendor/useGetVendors';
import { AppComponent, SolarDesign } from '@app-types/general';

type Tab = 'designs' | 'components' | 'vendors';

export const Admin = () => {
  const [activeTab, setActiveTab] = useState('designs');
  const [selectedDesign, setSelectedDesign] = useState<string>();
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDesignDetails, setSelectedDesignDetails] = useState<SolarDesign>();
  const [designPageView, setDesignPageView] = useState('main');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isGridTiedWBatteryPopupOpen, setIsGridTiedWBatteryPopupOpen] = useState(false);
  const [selectedDesignId, setSelectedDesignId] = useState<number>();
  const [selectedVersionId, setSelectedVersionId] = useState<number>();
  const [showDesignPopup, setShowDesignPopup] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState<AppComponent>();
  const [isComponentDetailsOpen, setIsComponentDetailsOpen] = useState(false);
  const [newVendor, setNewVendor] = useState({ name: '', website: '' });
  const [showAddComponentPopup, setShowAddComponentPopup] = useState(false);

  const { getVendors } = useGetVendors();
  const { addVendors } = useAddVendors();
  const { getSolarDesigns } = useGetSolarDesigns();
  const { getDesignVersions } = useGetDesignVersions();
  const { getComponents } = useGetComponents();
  const { getComponentDetails } = useGetComponentDetails();
  const { getDesignComponents } = useGetDesignComponents();

  const vendors = getVendors.data?.result || [];
  const solarDesigns = getSolarDesigns.data?.result || [];
  const components = getComponents.data?.result || [];
  const designVersions = getDesignVersions.data?.result || [];
  const componentsData = getDesignComponents.data?.result || [];

  useEffect(() => {
    if (activeTab === 'designs' && designPageView === 'main') {
      getSolarDesigns.fetch();
    }
  }, [activeTab, designPageView]);

  const handleTabClick = (tab: Tab) => {
    setActiveTab(tab);

    switch (tab) {
      // case 'designs':
      //   getDesignGetSolarDesigns.fetch();
      //   break;
      case 'components':
        return getComponents.fetch();
      case 'vendors':
        return getVendors.fetch();
      default:
        break;
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setIsGridTiedWBatteryPopupOpen(false);
    setShowDesignPopup(false);
  };

  return (
    <>
      <Header />
      <section className="py-100">
        <div className="container mt-4">
          <div className="d-flex justify-content-center">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'designs' ? 'active' : ''}`}
                  onClick={() => handleTabClick('designs')}
                  style={{ color: activeTab === 'designs' ? '#007bff' : '#000' }}
                >
                  Designs
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'components' ? 'active' : ''}`}
                  onClick={() => handleTabClick('components')}
                  style={{ color: activeTab === 'components' ? '#007bff' : '#000' }}
                >
                  Components
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeTab === 'vendors' ? 'active' : ''}`}
                  onClick={() => handleTabClick('vendors')}
                  style={{ color: activeTab === 'vendors' ? '#007bff' : '#000' }}
                >
                  Vendors
                </button>
              </li>
            </ul>
          </div>

          {activeTab === 'designs' && designPageView === 'main' && (
            <div className="mt-4 main">
              <h2 className="text-center">Solar Design Types</h2>
              <Table bordered>
                <thead className="bg-primary text-white">
                  <tr>
                    <th>Design Type</th>
                    <th>Components Description</th>
                    <th>Number of Implementations</th>
                    <th>Edit</th>
                    <th>Select</th>
                  </tr>
                </thead>
                <tbody>
                  {solarDesigns.length > 0 ? (
                    solarDesigns.map((design) => (
                      <tr
                        key={design.designId}
                        className={selectedDesign === design.designType ? 'bg-light' : ''}
                      >
                        <td>{design.designType}</td>
                        <td>{design.componentsDescription}</td>
                        <td>{design.numberOfImplementations}</td>
                        <td>
                          <FaEdit
                            className={
                              selectedDesign === design.designType ? 'text-success' : 'text-muted'
                            }
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              getDesignComponents.fetch(
                                {
                                  designId: design.designId
                                },
                                {
                                  onAfterSuccess: () => {
                                    setSelectedDesign(design.designType);

                                    if (selectedDesignId) {
                                      setSelectedDesignDetails({
                                        ...design,
                                        designId: selectedDesignId
                                      });
                                    }

                                    setIsPopupOpen(true);
                                  }
                                }
                              );
                            }}
                          />
                        </td>
                        <td>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="radioNoLabel"
                            id={`radioNoLabel${design.designId}`}
                            value={design.designId}
                            onChange={() => setSelectedDesignId(design.designId)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center">
                        No designs available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="d-flex justify-content-end mx-2 mt-3">
                <Button
                  variant="primary"
                  className="mx-2"
                  onClick={() => {
                    setSelectedDesignDetails(undefined);
                    setIsPopupOpen(true);
                    setShowDesignPopup(true);
                  }}
                >
                  Add Design
                </Button>
                <Button
                  variant="success"
                  onClick={() => {
                    if (!selectedDesignId) return;

                    getDesignVersions.fetch(
                      {
                        designId: selectedDesignId
                      },
                      {
                        onAfterSuccess: () => {
                          setDesignPageView('detail');
                        }
                      }
                    );
                  }}
                >
                  Next
                </Button>
              </div>
            </div>
          )}

          {activeTab === 'designs' && designPageView === 'detail' && (
            <div className="mt-4 main">
              <h2 className="text-center">Design Versions</h2>
              <Table bordered>
                <thead className="bg-primary text-white">
                  <tr>
                    <th>Description</th>
                    <th>Inverter</th>
                    <th>Battery</th>
                    <th>Panels</th>
                    <th>PV Power</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {designVersions.length > 0 ? (
                    designVersions.map((version) => (
                      <tr key={version.versionId}>
                        <td>{version.description}</td>
                        <td>{version.inverter}</td>
                        <td>{version.battery}</td>
                        <td>{version.panels}</td>
                        <td>{version.pvPower}</td>
                        <td>{version.status.description}</td>
                        <td>
                          <FaEdit
                            className="text-muted"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedVersionId(version.versionId);
                              setIsGridTiedWBatteryPopupOpen(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7} className="text-center">
                        No versions available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between mt-3">
                <Button
                  variant="secondary"
                  onClick={() => {
                    setDesignPageView('main');
                  }}
                >
                  Prev
                </Button>
                <Button variant="primary">Add Version</Button>
                <Button variant="success">Finish</Button>
              </div>
            </div>
          )}

          {activeTab === 'components' && (
            <div className="mt-4 main">
              <h2 className="text-center">Components</h2>
              <Table bordered>
                <thead className="bg-primary text-white">
                  <tr>
                    <th>Type</th>
                    <th>Brand</th>
                    <th>Model</th>
                    <th>Power</th>
                    <th>Image URL</th>
                    <th>Datasheet</th>
                    <th>Details</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {components.length > 0 ? (
                    components.map((component) => (
                      <tr key={component.componentId}>
                        <td>{component.type}</td>
                        <td>{component.brand}</td>
                        <td>{component.model}</td>
                        <td>{component.power}</td>
                        <td>{component.imageUrl || 'N/A'}</td>
                        <td>{component.datasheet || 'N/A'}</td>
                        <td>
                          <a
                            href="#"
                            onClick={() => {
                              getComponentDetails.fetch(
                                {
                                  componentId: component.componentId
                                },
                                {
                                  onAfterSuccess: ({ result }) => {
                                    setSelectedComponent(result);
                                    setIsComponentDetailsOpen(true);
                                  }
                                }
                              );
                            }}
                          >
                            View Details
                          </a>
                        </td>
                        <td>
                          <FaEdit
                            className="text-muted"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setSelectedComponent(component);
                              setShowPopup(true);
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8} className="text-center">
                        No components available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="d-flex justify-content-end mt-3">
                <Button
                  variant="primary"
                  className="mx-2"
                  onClick={() => {
                    setShowAddComponentPopup(true);
                  }}
                >
                  Add Component
                </Button>

                <Button variant="success">Finish</Button>
              </div>
            </div>
          )}

          {activeTab === 'vendors' && (
            <div className="mt-4 main">
              <h2 className="text-center">Vendors</h2>
              <p className="text-center">Please provide the information of the new vendor</p>

              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label className="form-label">Vendor Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter vendor name"
                      name="name"
                      value={newVendor.name}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setNewVendor((prevState) => ({ ...prevState, [name]: value }));
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Website:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter website URL"
                      name="website"
                      value={newVendor.website}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setNewVendor((prevState) => ({ ...prevState, [name]: value }));
                      }}
                    />
                  </div>
                  <Button
                    variant="primary"
                    onClick={() => {
                      addVendors.fetch(newVendor, {
                        onAfterSuccess: () => {
                          alert('Vendor added successfully');

                          getVendors.fetch();
                          setNewVendor({ name: '', website: '' });
                        }
                      });
                    }}
                  >
                    Save
                  </Button>
                </div>

                <div className="col-md-6">
                  <h4 className="text-center">Current Vendors:</h4>
                  <select className="form-select" size={7}>
                    {vendors.length > 0 ? (
                      vendors.map((vendor) => <option key={vendor.vendorId}>{vendor.name}</option>)
                    ) : (
                      <option>No vendors available</option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      {isPopupOpen && (
        <DesignPopup
          design={selectedDesignDetails}
          components={componentsData}
          onClose={handleClosePopup}
        />
      )}

      {showPopup && selectedComponent && (
        <ComponentPopup component={selectedComponent} onClose={() => setShowPopup(false)} />
      )}

      {isComponentDetailsOpen && selectedComponent && (
        <ComponentDetails
          component={selectedComponent}
          onClose={() => {
            setIsComponentDetailsOpen(false);
            setSelectedComponent(undefined);
          }}
        />
      )}

      {isGridTiedWBatteryPopupOpen && selectedVersionId && (
        <GridTiedPopup versionId={selectedVersionId} onClose={handleClosePopup} />
      )}

      {showDesignPopup && <AddDesign design={solarDesigns} onClose={handleClosePopup} />}

      {showAddComponentPopup && (
        <AddComponent
          component={selectedComponent}
          onClose={() => {
            setShowAddComponentPopup(false);
          }}
        />
      )}
    </>
  );
};
