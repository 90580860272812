import { useFetch } from '@hooks/useFetch';

import { ValidationSchemaState } from '@component/common/validationSchema/validationschema';

import { FetchResource } from '@app-types/api';
import { AnyRecord } from '@app-types/general';
import { getEndpoint } from '@utils/api';

type UseSaveReportArgs = Record<keyof ValidationSchemaState, string> & {
  timeZone?: string;
  consumption: Array<{
    month: string;
    avgkW: number;
  }>;
};

export const useAddReport = (): {
  addReport: FetchResource<UseSaveReportArgs, AnyRecord | boolean>;
} => {
  const fetch = useFetch<AnyRecord | boolean>();

  return {
    addReport: {
      data: fetch[0],
      status: fetch[1],
      fetch: (
        {
          systemTypeId,
          address,
          city,
          state,
          zip,
          buildingTypeId,
          coolingTypeId,
          heatingTypeId,
          roofAge,
          suiteNum,
          consumption,
          utilityCharge,
          estimatedkWPerHour,
          yearlyElectricityCost,
          percentOfEnergyToGenerate,
          energySupplierId,
          roles,
          timeZone
        },
        options = {}
      ) => {
        const parsedSystemTypeId = parseInt(systemTypeId);
        const parseBuildingTypeId = parseInt(buildingTypeId);
        const parsedCoolingTypeId = parseInt(coolingTypeId);
        const parsedHeatingTypeId = parseInt(heatingTypeId);
        const parsedRoofAge = parseFloat(roofAge);
        const parsedUtilityCharge = parseFloat(utilityCharge);
        const parsedEstimatedkWPerHour = parseFloat(estimatedkWPerHour);
        const parsedsuiteNum = parseFloat(suiteNum);
        const parsedYearlyElectricityCost = parseFloat(yearlyElectricityCost);
        const parsedPercentOfEnergyToGenerate = parseFloat(percentOfEnergyToGenerate);
        const parsedEnergySupplierId = parseInt(energySupplierId);

        fetch[2](
          {
            method: 'post',
            url: getEndpoint({ path: '/report/save-report' }),
            data: {
              systemTypeId: parsedSystemTypeId,
              roles,
              address,
              city,
              state,
              zip,
              buildingTypeId: parseBuildingTypeId,
              coolingTypeId: parsedCoolingTypeId,
              heatingTypeId: parsedHeatingTypeId,
              roofAge: parsedRoofAge,
              suiteNum: parsedsuiteNum,
              consumption,
              utilityCharge: parsedUtilityCharge,
              estimatedkWPerHour: parsedEstimatedkWPerHour,
              yearlyElectricityCost: parsedYearlyElectricityCost,
              percentOfEnergyToGenerate: parsedPercentOfEnergyToGenerate,
              energySupplierId: parsedEnergySupplierId,
              timeZone
            }
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
