import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { AnyRecord } from '@app-types/general';
import { getEndpointSolar } from '@utils/api';

export const useComputeTilesPosition = (): {
  computeTilesPosition: FetchResource<AnyRecord, AnyRecord>;
} => {
  const fetch = useFetch<AnyRecord>();

  return {
    computeTilesPosition: {
      data: fetch[0],
      status: fetch[1],
      fetch: (data, options = {}) => {
        fetch[2](
          {
            method: 'post',
            url: getEndpointSolar({
              path: '/design/compute-tiles-position'
            }),
            data
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
