import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { getEndpoint } from '@utils/api';

type UseGetItemValuesResult = Array<{
  id: string;
  description: string;
}>;

export const useGetItemValues = (): {
  getItemValues: FetchResource<{ itemValueType: number }, UseGetItemValuesResult>;
} => {
  const fetch = useFetch<UseGetItemValuesResult>();

  return {
    getItemValues: {
      data: fetch[0],
      status: fetch[1],
      fetch: ({ itemValueType }, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({ path: '/item-values/get-item-values', query: { itemValueType } })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
