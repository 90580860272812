import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { SolarDesign } from '@app-types/general';
import { getEndpoint } from '@utils/api';

export const useGetSolarDesigns = (): {
  getSolarDesigns: FetchResource<void, Array<SolarDesign>>;
} => {
  const fetch = useFetch<Array<SolarDesign>>();

  return {
    getSolarDesigns: {
      data: fetch[0],
      status: fetch[1],
      fetch: (_, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({
              path: '/design/get-solar-designs'
            })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
