import {Container, Nav, Navbar} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useRouter } from '@features/router/useRouter';

import { useAuth } from '@hooks/useAuth';

import LOGO from '@assets/logo.png';

import './index.css';
import { compact } from '@utils/general';

export const Header = () => {
  const { authData, authSignIn } = useAuth();
  const { pushRoute } = useRouter();

  const firstName = authData?.firstName;

  const greeting = firstName ? `Hi, ${firstName}` : 'Login';

  const links = compact<{
    path?: string;
    label: string;
    onClick?: () => void;
  }>([
    { path: '/solar_design', label: 'My solar Design' },
    { path: '/admin', label: 'Admin' },
    { path: '/blog', label: 'Blog' },
    { path: '/faq', label: 'Faq' },
    { path: '/contactus', label: 'Contact Us' },
    { path: '/aboutus', label: 'About Us' },
    { path: '/map', label: 'Map' },
    authData && { path: '/login', label: greeting },
    authData && {
      path: '/login',
      label: 'Logout',
      onClick: () => authSignIn.reset()
    },
    !authData && { path: '/login', label: 'Login' }
  ]);

  // if(!pathList.includes(location.pathname)) {
  //   return null;
  // }

  return (
    <div id="main_header">
      <Navbar expand="md">
        <Container>
          <div className="w-100 d-flex align-items-center">
            <div className="logo" onClick={() => pushRoute('/')}>
              <span style={{ cursor: 'pointer' }}>
                <img src={LOGO} alt="USRoof" />
              </span>
            </div>
            <div className="ms-auto">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav>
                  {links.map(({ label, onClick, path }, index) => {
                    if (path) {
                      return (
                        <Nav.Item key={index}>
                          <Link to={path} onClick={onClick}>
                            {label}
                          </Link>
                        </Nav.Item>
                      );
                    }

                    return (
                      <Nav.Item key={index} onClick={onClick}>
                        {label}
                      </Nav.Item>
                    );
                  })}
                </Nav>
              </Navbar.Collapse>
            </div>
          </div>
        </Container>
      </Navbar>
    </div>
  );
};
