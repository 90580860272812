import { forwardRef, useRef, useState } from 'react';
import { FormControl, FormControlProps, InputGroup } from 'react-bootstrap';

import { FieldGroup, FieldGroupProps } from '@component/field-group';
import { useFormField } from '@component/formux/useFormField';

import { SvgEye } from './SvgEye';

import { isNumber, mergeRefs } from '@utils/general';

export interface FieldInputProps
  extends FieldGroupProps,
    Pick<React.InputHTMLAttributes<HTMLInputElement>, 'maxLength'> {
  autoFocusDelay?: number;
  label?: string;
  placeholder?: string;
  name?: string;
  type?: 'email' | 'password' | 'text' | 'number';
}

export const FieldInput = forwardRef<HTMLInputElement, FieldInputProps>((props, ref) => {
  const { type, placeholder, maxLength, ...omittedProps } = props;

  const refInternal = useRef<HTMLInputElement>(null);
  const { field, error } = useFormField(props);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const renderContainer = (content: React.ReactNode) => (
    <FieldGroup error={error} {...omittedProps}>
      {content}
    </FieldGroup>
  );

  const renderFormControl = (partialProps: Partial<FormControlProps> = {}) => {
    return (
      <FormControl
        ref={mergeRefs([refInternal, ref])}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        onInput={(e) => {
          /**
           * https://stackoverflow.com/questions/18510845/maxlength-ignored-for-input-type-number-in-chrome
           */
          // @ts-expect-error this work fine
          if (isNumber(maxLength) && e.target.value?.length > maxLength) {
            // @ts-expect-error this work fine
            e.target.value = e.target.value.slice(0, maxLength);
          }
        }}
        {...field}
        {...partialProps}
      />
    );
  };

  if (type === 'password') {
    const passwordFormControl = renderFormControl({
      type: passwordVisible ? 'text' : 'password'
    });

    return renderContainer(
      <InputGroup>
        {passwordFormControl}
        <InputGroup.Text onClick={() => setPasswordVisible((v) => !v)}>
          <SvgEye />
        </InputGroup.Text>
      </InputGroup>
    );
  }

  return renderContainer(renderFormControl());
});
