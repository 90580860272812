import { FormContextState } from "@component/formux/types";
import { useForm } from "@component/formux/useForm";

export interface FormuxDataContainerProps {
  children: (args: FormContextState) => React.ReactNode;
}
export const FormuxDataContainer = ({ children }: FormuxDataContainerProps) => {
  const formState = useForm();

  return <>{children(formState)}</>;
};
