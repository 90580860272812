import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { AnyRecord } from '@app-types/general';
import { getEndpoint } from '@utils/api';

export const useAddVendors = (): {
  addVendors: FetchResource<{ name: string; website: string }, AnyRecord>;
} => {
  const fetch = useFetch<AnyRecord>();

  return {
    addVendors: {
      data: fetch[0],
      status: fetch[1],
      fetch: (data, options = {}) => {
        fetch[2](
          {
            method: 'post',
            url: getEndpoint({
              path: '/vendor/post-vendors'
            }),
            data
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
