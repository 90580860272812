import { FormGroup, FormGroupProps, FormLabel } from 'react-bootstrap';

import { ChildrenProp } from '@app-types/general';

export interface FieldGroupProps extends FormGroupProps, ChildrenProp {
  label?: string;
  error?: string;
}

export const FieldGroup = ({
  children,
  label,
  error,
  ...omittedProps
}: FieldGroupProps) => {
  return (
    <FormGroup {...omittedProps}>
      <FormLabel>{label}</FormLabel>
      {children}
      <p style={{ color: 'red', paddingTop: 3 }}>{error}</p>
    </FormGroup>
  );
};
