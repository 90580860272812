import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { getEndpointSolar } from '@utils/api';

/**
 * TODO move this api key to other side
 */
const key = 'AIzaSyB5fF9C_VlMQmTOIrK4_bgCzwEukEHqsuE';

interface UseGetDataLayerResponse {
  monthlyFluxUrl: string;
  maskUrl: string;
}

export const useGetDataLayer = (): {
  getDataLayer: FetchResource<
    { lat: number; lon: number; radius: number },
    UseGetDataLayerResponse
  >;
} => {
  const fetch = useFetch<UseGetDataLayerResponse>();

  return {
    getDataLayer: {
      data: fetch[0],
      status: fetch[1],
      fetch: ({ lat, lon, radius }, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpointSolar({
              path: '/dataLayers:get',
              query: {
                key,
                'location.latitude': lat.toFixed(5),
                'location.longitude': lon.toFixed(5),
                radius_meters: radius?.toString(),
                // The Solar API always returns the highest quality imagery available.
                // By default the API asks for HIGH quality, which means that HIGH quality isn't available,
                // but there is an existing MEDIUM or LOW quality, it won't return anything.
                // Here we ask for *at least* LOW quality, but if there's a higher quality available,
                // the Solar API will return us the highest quality available.
                required_quality: 'LOW'
              }
            })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
