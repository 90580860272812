import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { SolaDesignVersion } from '@app-types/general';
import { getEndpoint } from '@utils/api';

export const useGetDesignVersions = (): {
  getDesignVersions: FetchResource<{ designId: number }, Array<SolaDesignVersion>>;
} => {
  const fetch = useFetch<Array<SolaDesignVersion>>();

  return {
    getDesignVersions: {
      data: fetch[0],
      status: fetch[1],
      fetch: ({ designId }, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({
              path: '/design/get-design-versions',
              query: {
                designId
              }
            })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
