import { dynamic, LoadableReturn } from '@utils/makeLazy';
import { ModalId } from './types';

import { useModal } from './useModal';

const componentRecord: Record<ModalId, LoadableReturn> = {
  Emergent: dynamic(() => import('./components/emergent').then((m) => m)),
  Confirmation: dynamic(() => import('./components/confirmation').then((m) => m))
};

export const ModalContainer = (): JSX.Element | null => {
  const { allModalData } = useModal();

  return (
    <>
      {allModalData?.map(({ id, props }, index) => {
        const Component = componentRecord[id];

        return Component ? <Component key={index} {...props} /> : null;
      })}
    </>
  );
};
