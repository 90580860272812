export const SvgEye = () => {
  return (
    <svg
      width="16"
      height="14"
      x="0px"
      y="0px"
      viewBox="0 0 16 14"
      //@ts-expect-error TODO: DEPRECATED FEATURE
      style={{ enableBackground: 'new 0 0 16 14' }}
    >
      <path
        d="M13.359 10.238C15.06 8.72 16 7 16 7C16 7 13 1.5 8 1.5C7.03959 1.5033 6.09005 1.70342 5.21 2.088L5.98 2.859C6.62744 2.62315 7.31095 2.50167 8 2.5C10.12 2.5 11.879 3.668 13.168 4.957C13.7884 5.58069 14.3445 6.26512 14.828 7C14.77 7.087 14.706 7.183 14.633 7.288C14.298 7.768 13.803 8.408 13.168 9.043C13.003 9.208 12.831 9.371 12.651 9.529L13.359 10.238Z"
        fill="#A0A6BB"
      />
      <path
        d="M11.297 8.176C11.5202 7.55184 11.5615 6.87714 11.4162 6.23042C11.2709 5.58369 10.9449 4.99152 10.4762 4.52282C10.0075 4.05411 9.41531 3.72814 8.76858 3.58283C8.12185 3.43752 7.44716 3.47884 6.823 3.702L7.646 4.525C8.03031 4.46999 8.42215 4.50524 8.79047 4.62796C9.15879 4.75068 9.49347 4.95749 9.76799 5.23201C10.0425 5.50653 10.2493 5.8412 10.372 6.20953C10.4948 6.57785 10.53 6.96969 10.475 7.354L11.297 8.176ZM8.354 9.475L9.176 10.297C8.55184 10.5202 7.87715 10.5615 7.23042 10.4162C6.58369 10.2709 5.99153 9.94488 5.52282 9.47618C5.05411 9.00747 4.72814 8.4153 4.58283 7.76858C4.43752 7.12185 4.47885 6.44715 4.702 5.823L5.525 6.646C5.46999 7.03031 5.50524 7.42215 5.62796 7.79047C5.75068 8.15879 5.95749 8.49347 6.23201 8.76799C6.50653 9.0425 6.84121 9.24931 7.20953 9.37203C7.57785 9.49475 7.96969 9.53 8.354 9.475Z"
        fill="#A0A6BB"
      />
      <path
        d="M3.35 4.47C3.17 4.63 2.997 4.792 2.832 4.957C2.21165 5.58069 1.65552 6.26512 1.172 7L1.367 7.288C1.702 7.768 2.197 8.408 2.832 9.043C4.121 10.332 5.881 11.5 8 11.5C8.716 11.5 9.39 11.367 10.02 11.14L10.79 11.912C9.90994 12.2965 8.9604 12.4967 8 12.5C3 12.5 0 7 0 7C0 7 0.939 5.279 2.641 3.762L3.349 4.471L3.35 4.47ZM13.646 13.354L1.646 1.354L2.354 0.645996L14.354 12.646L13.646 13.354Z"
        fill="#A0A6BB"
      />
    </svg>
  );
};
