import { useEffect, useState } from 'react';

import './index.css';
import { useUpdateSolarDesign } from '@api/design/useUpdateSolarDesign';
import { SolarComponent } from '@app-types/general';
import { isNullOrUndefined } from '@utils/general';


export interface DesignPopupProps {
  design?: any;
  onClose: () => void;
  components?: Array<SolarComponent>;
}

export const DesignPopup = ({ design, onClose, components }: DesignPopupProps) => {
  const isEditMode = design && design.name !== undefined;

  const [availableComponents, setAvailableComponents] = useState(components || []);
  const [selectedComponents, setSelectedComponents] = useState<Array<SolarComponent>>([]);
  const [designType, setDesignType] = useState(design ? design.name : '');
  const [selectedComponentId, setSelectedComponentId] = useState<number>();

  useEffect(() => {
    if (design) {
      setDesignType(design.designType);
      setAvailableComponents(components || []);
    }
  }, [design, components]);

  const moveToSelected = () => {
    if (!isNullOrUndefined(selectedComponentId)) {
      const componentToMove = availableComponents.find((comp) => comp.id === selectedComponentId);
      if (componentToMove) {
        setSelectedComponents((prev) => [...prev, componentToMove]);
        setAvailableComponents((prev) =>
          prev.filter((component) => component.id !== selectedComponentId)
        );
        setSelectedComponentId(undefined);
      }
    }
  };

  const moveToAvailable = () => {
    if (!isNullOrUndefined(selectedComponentId)) {
      const componentToMove = selectedComponents.find((comp) => comp.id === selectedComponentId);
      if (componentToMove) {
        setAvailableComponents((prev) => [...prev, componentToMove]);
        setSelectedComponents((prev) =>
          prev.filter((component) => component.id !== selectedComponentId)
        );
        setSelectedComponentId(undefined);
      }
    }
  };

  const handleComponentClick = (id: number) => {
    setSelectedComponentId((prevId) => {
      const newId = prevId === id ? undefined : id;
      return newId;
    });
  };

  const { updateSolarDesign } = useUpdateSolarDesign();
  const saveDesign = async () => {
    const designData = {
      designId: design ? design.designId : 0,
      designType: designType,
      description: selectedComponents.map((component) => component.description).join(', '),
      designComponentIds: selectedComponents.map((component) => component.id)
    };

    updateSolarDesign.fetch(designData, {
      onAfterSuccess: () => {
        onClose();
      }
    });
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h2 className="text-center">
            {isEditMode
              ? 'Please modify the information of the selected design type'
              : 'Please add a new design type'}
          </h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="popup-body">
          <label htmlFor="designType">Design Type:</label>
          <input
            type="text"
            id="designType"
            value={designType}
            onChange={(e) => setDesignType(e.target.value)}
            className="design-type-input"
            placeholder={!isEditMode ? 'Enter design name' : ''}
          />

          <div className="components-section">
            <div className="components-list-container">
              <h3>Available component types</h3>
              <ul className="components-list">
                {availableComponents.length > 0 ? (
                  availableComponents.map((component) => (
                    <li
                      key={component.id}
                      className={selectedComponentId === component.id ? 'selected' : ''}
                      onClick={() => handleComponentClick(component.id)}
                    >
                      {component.componentsDescription} - {component.description}
                    </li>
                  ))
                ) : (
                  <li>No components available</li>
                )}
              </ul>
            </div>
            <div className="buttons-section">
              <button className="transfer-button" onClick={moveToSelected}>
                {' '}
                &gt;{' '}
              </button>
              <button className="transfer-button" onClick={moveToAvailable}>
                {' '}
                &lt;{' '}
              </button>
            </div>
            <div className="components-list-container">
              <h3>Selected component types</h3>
              <ul className="components-list">
                {selectedComponents.length > 0 ? (
                  selectedComponents.map((component) => (
                    <li
                      key={component.id}
                      className={selectedComponentId === component.id ? 'selected' : ''}
                      onClick={() => handleComponentClick(component.id)}
                    >
                      {component.componentsDescription} - {component.description}
                    </li>
                  ))
                ) : (
                  <li>No components selected</li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="popup-actions">
          <button className="save-button" onClick={saveDesign}>
            {isEditMode ? 'Save' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};
