import { PersistentProvider } from '@features/persistent';
import { RouterProvider } from '@features/router/RouterProvider';

import { ReduxProvider } from './Redux/ReduxProvider';
import { ModalContainer } from '@features/modal';

export const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <PersistentProvider>
      <ReduxProvider>
        <RouterProvider>
          <ModalContainer />
          {children}
        </RouterProvider>
      </ReduxProvider>
    </PersistentProvider>
  );
};
