import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { getEndpoint } from '@utils/api';

export const useGetVersionDetails = (): {
  getVersionDetails: FetchResource<{ versionId: number }, Array<any>>;
} => {
  const fetch = useFetch<Array<any>>();

  return {
    getVersionDetails: {
      data: fetch[0],
      status: fetch[1],
      fetch: ({ versionId }, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({
              path: '/design/get-version-details',
              query: { versionId }
            })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
