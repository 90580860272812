import '../component-popup/index.css';
import { AppComponent } from '@app-types/general';

export interface ComponentDetailsProps {
  component: AppComponent;
  onClose: () => void;
}

export const ComponentDetails = ({ component, onClose }: ComponentDetailsProps) => {
  if (!component) return null;

  const fields = component.componentFields.reduce((acc, field) => {
    return {
      ...acc,
      [field.fieldName]: field.value
    }
    return acc;
  }, {} as Record<string, string | number>);

  return (
    <div className="component-popup-overlay">
      <div className="component-popup-content">
        <button className="component-popup-close" onClick={onClose}>
          ×
        </button>
        <h3 className="component-popup-title">Component Details</h3>
        <div className="component-popup-body">
          <div className="component-popup-image">
            <div className="component-image-placeholder">Image</div>
          </div>
          <div className="component-popup-details">
            <p>
              <strong>Component Type:</strong> {component.type}
            </p>
            <p>
              <strong>Component ID:</strong> {component.componentId}
            </p>

            {/* Display component fields */}
            {Object.keys(fields).map((fieldName) => (
              <p key={fieldName}>
                <strong>{fieldName}:</strong> {fields[fieldName]}
              </p>
            ))}

            {/* Display vendors */}
            <div className="component-popup-vendors">
              <h4>Vendors:</h4>
              <ul>
                {component.vendors && component.vendors.length > 0 ? (
                  component.vendors.map((vendor, index) => (
                    <li key={index}>
                      Vendor ID: {vendor.vendorId}, Price: ${vendor.price}, Shipping Cost for Min
                      Order: ${vendor.shippingCostForMinOrder}, Min Order: {vendor.minOrder}
                    </li>
                  ))
                ) : (
                  <li>No vendors available</li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="component-popup-footer">
          <button className="component-popup-ok-btn" onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};