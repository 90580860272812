import { TailSpin } from 'react-loader-spinner';

import './index.css';
export const Loader = () => {
  return (
    <div className="loader-container">
      <TailSpin
        height="100"
        width="100"
        color="#4fa94d"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};
