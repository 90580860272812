import { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';

export interface VendorSelectionModalProps {
  show: boolean;
  onHide: () => void;
  vendors: Array<string>;
  onSelectVendor: (vendor: string) => void;
}

export const VendorSelectionModal = ({
  show,
  onHide,
  vendors,
  onSelectVendor
}: VendorSelectionModalProps) => {
  const [selectedVendor, setSelectedVendor] = useState('');

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="custom-text-center">
          Please select a vendor and fill the information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Select Vendor</Form.Label>
              <Form.Control
                as="select"
                size="lg"
                className="custom-vendor-list"
                value={selectedVendor}
                onChange={(e) => {
                  const vendor = e.target.value;
                  setSelectedVendor(vendor);
                  onSelectVendor(vendor);
                }}
              >
                <option value="">Select a vendor</option>
                {vendors.map((vendor, index) => (
                  <option key={index} value={vendor}>
                    {vendor}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Price:</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group>
              <Form.Label>Min Order:</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
            <Form.Group>
              <Form.Label>Shipping cost:</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>{' '}
        {/* Close button to hide the modal */}
      </Modal.Footer>
    </Modal>
  );
};
