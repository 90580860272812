import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { useRouter } from '@features/router/useRouter';

import { useAuth } from '@hooks/useAuth';

import {
  validationSchema,
  ValidationSchemaState
} from '@component/common/validationSchema/validationschema';
import { Header } from '@component/header';
import { Loader } from '@component/loader';

import { SideBar } from './SideBar';

import './index.css';
import { useGetGeolocationAutocomplete } from '@api/geolocation/useGetGeolocationAutocomplete';
import { useGetGeolocationDetails } from '@api/geolocation/useGetGeolocationDetails';
import { useGetGeolocationGeocode } from '@api/geolocation/useGetGeolocationGeocode';
import { useGetItemValues } from '@api/item-values/useGetItemValues';
import { useAddReport } from '@api/reports/useAddReport';
import { useGetReports } from '@api/reports/useGetReports';
import { yupResolver } from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';

interface StateData {
  sliderValue: number;
  getReportSlider: number;
  getReportParameter: number;
  selectedCoolingType: string;
  selectedHeatingType: string;
  selectedEnergySupplierType: string;
  predictions: Array<{
    address: string;
    placeId: string;
  }>;
  mapCenter: { lat: number; lng: number };
  address: string;
  city: string;
  state: string;
  zip: string;
}

const Link = ({
  id,
  children,
  title
}: {
  id: string;
  children: React.ReactElement;
  title: string;
}) => <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>{children}</OverlayTrigger>;

export const SolarDesign = () => {
  const [stateData, setStateData] = useState<StateData>({
    sliderValue: 80,
    getReportSlider: 0,
    getReportParameter: 0,
    selectedCoolingType: '',
    selectedHeatingType: '',
    selectedEnergySupplierType: '',
    predictions: [],
    mapCenter: { lat: 0, lng: 0 },
    address: '',
    city: '',
    state: '',
    zip: ''
  });

  const { authData } = useAuth();
  const { pushRoute } = useRouter();
  /**
   * Item values
   */
  const { getItemValues: getCoolingData } = useGetItemValues();
  const { getItemValues: getHeatingData } = useGetItemValues();
  const { getItemValues: getEnergyProviderData } = useGetItemValues();

  const coolingData = getCoolingData.data?.result;
  const heatingData = getHeatingData.data?.result;
  const energyProviderData = getCoolingData.data?.result;

  /**
   * Geolocation service
   */
  const { getGeolocationAutocomplete } = useGetGeolocationAutocomplete();
  const { getGeolocationGeocode } = useGetGeolocationGeocode();
  const { getGeolocationGeocode: getGeolocationGeocodeZip } = useGetGeolocationGeocode();
  const { getGeolocationDetails } = useGetGeolocationDetails();

  const geolocation = getGeolocationGeocode.data?.result?.[0];
  const zipcodelocation = getGeolocationGeocodeZip.data?.result?.[0];
  const placeIdDetails = getGeolocationDetails.data?.result;
  const searchAddresData = getGeolocationAutocomplete.data?.result;

  /**
   * Reports
   */

  const { getReports } = useGetReports();
  const { addReport } = useAddReport();

  const ReportData = getReports.data?.result?.[0] || {};
  const reportData = ReportData.reportData;
  const reportParameters = ReportData.reportParameters;
  const isLoading = getReports.status.isBusy;
  /**
   * form
   */

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { errors }
  } = useForm<ValidationSchemaState>({
    //@ts-expect-error TODO check this missing type
    resolver: yupResolver<ValidationSchemaState>(validationSchema),
    defaultValues: {
      systemTypeId: 1,
      buildingTypeId: '1',
      roles: []
    },
    mode: 'onChange'
  });

  useEffect(() => {
    const { city, state, postalCode, address } = zipcodelocation || {};
    if (zipcodelocation && city && state && postalCode && address) {
      setStateData((prevState) => ({
        ...prevState,
        address: address,
        state: state,
        city: city,
        zip: postalCode
      }));
      setValue('address', address);
      setValue('city', city);
      setValue('state', state);
      setValue('zip', postalCode);
    }
  }, [zipcodelocation]);

  useEffect(() => {
    if (Array.isArray(searchAddresData)) {
      const updatedPredictions = searchAddresData.map((item) => ({
        address: item.address,
        placeId: item.placeId
      }));
      if (updatedPredictions && updatedPredictions.length > 0) {
        setStateData((prevState) => ({
          ...prevState,
          predictions: updatedPredictions
        }));
      }
    }
  }, [searchAddresData]);

  useEffect(() => {
    if (geolocation) {
      const { address, city, state, postalCode } = geolocation;

      setStateData((prevState) => ({
        ...prevState,
        address: address,
        state: state,
        city: city,
        zip: postalCode
      }));
      setValue('address', address);
      setValue('city', city);
      setValue('state', state);
      setValue('zip', postalCode);
    }
  }, [geolocation]);

  useEffect(() => {
    if (placeIdDetails) {
      const { address, city, state, postalCode } = placeIdDetails;
      if (address) {
        setStateData((prevState) => ({
          ...prevState,
          address: address
        }));
        setValue('address', address);
      }
      if (city) {
        setStateData((prevState) => ({
          ...prevState,
          city: city
        }));
        setValue('city', city);
      }
      if (state) {
        setStateData((prevState) => ({
          ...prevState,
          state: state
        }));
        setValue('state', state);
      }
      if (postalCode) {
        setStateData((prevState) => ({
          ...prevState,
          zip: postalCode
        }));
        setValue('zip', postalCode);
      }
    }
  }, [placeIdDetails]);

  const handleAutofill = async () => {
    try {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const lat = position.coords.latitude;
            const lon = position.coords.longitude;

            getGeolocationGeocode.fetch({ lat, lon });
          },
          (error) => {
            console.error('Error getting location:', error.message);
          }
        );
      } else {
        console.error('Geolocation is not supported by your browser');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const formSubmit = async () => {
    const confirmationResult = await Swal.fire({
      title: 'Save Changes?',
      text: 'Do you want to save changes?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Save',
      cancelButtonText: 'Not Now'
    });

    if (confirmationResult.isConfirmed) {
      try {
        addReport.fetch(
          {
            ...getValues(),
            percentOfEnergyToGenerate: `${stateData.sliderValue}`,
            consumption: [
              {
                month: getValues('month'),
                avgkW: parseFloat(getValues('avgkW'))
              }
            ],
            //@ts-expect-error TODO check this missing type
            roles: getValues('roles')
          },
          {
            onAfterSuccess: (response) => {
              localStorage.setItem('savedResponse', JSON.stringify(response.result));
              if (response.result === true) {
                Swal.fire({
                  title: 'Great Job!',
                  icon: 'success',
                  text: response.message
                }).then((result) => {
                  if (result.isConfirmed) {
                    reset();
                  }
                });
              } else {
                localStorage.setItem('response', 'true');
                pushRoute('/login');
              }
            }
          }
        );
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  useEffect(() => {
    const storedResponse = localStorage.getItem('savedResponse');
    if (storedResponse) {
      const data = JSON.parse(storedResponse);
      const response = data.meta.arg;

      if (authData) {
        addReport.fetch(data, {
          onAfterSuccess: (response) => {
            localStorage.setItem('savedResponse', JSON.stringify(response.result));

            //TODO I don have idea what is it
            // if (response.meta.requestStatus == 'fulfilled') {
            //   getReports.fetch();
            // }
          }
        });
      }

      const coolingTypeId = response.coolingTypeId;
      const heatingTypeId = response.heatingTypeId;
      const energySupplierId = response.energySupplierId;
      const rolesData = response.roles || [];
      //@ts-expect-error TODO check this missing type
      document.getElementById('roofer').checked =
        //@ts-expect-error TODO check this missing type
        rolesData?.findIndex((ele) => ele?.roleId === 1) !== -1;
      //@ts-expect-error TODO check this missing type
      document.getElementById('electrician').checked =
        //@ts-expect-error TODO check this missing type
        rolesData?.findIndex((ele) => ele?.roleId === 2) !== -1;
      //@ts-expect-error TODO check this missing type
      document.getElementById('bearing_analysis').checked =
        //@ts-expect-error TODO check this missing type
        rolesData?.findIndex((ele) => ele?.roleId === 3) !== -1;

      if (coolingTypeId) {
        setStateData((prevState) => ({
          ...prevState,
          selectedCoolingType: coolingTypeId
        }));
        setValue('coolingTypeId', coolingTypeId);
      }

      if (heatingTypeId) {
        setStateData((prevState) => ({
          ...prevState,
          selectedCoolselectedHeatingTypeingType: heatingTypeId
        }));
        setValue('heatingTypeId', heatingTypeId);
      }

      if (energySupplierId) {
        setStateData((prevState) => ({
          ...prevState,
          selectedEnergySupplierType: energySupplierId
        }));
      }

      setValue('systemTypeId', response.systemTypeId);
      if (response.city) {
        setStateData((prevState) => ({
          ...prevState,
          city: response.city
        }));
        setValue('city', response.city);
      }
      setValue('roles', rolesData);
      if (response.address) {
        setStateData((prevState) => ({
          ...prevState,
          address: response.address
        }));
        setValue('address', response.address);
      }
      setValue('buildingTypeId', response.buildingTypeId);
      setValue('percentOfEnergyToGenerate', response.percentOfEnergyToGenerate);
      if (response.state) {
        setStateData((prevState) => ({
          ...prevState,
          state: response.state
        }));
        setValue('state', response.state);
      }
      setValue('zip', response.zip);
      if (response.address) {
        setStateData((prevState) => ({
          ...prevState,
          zip: response.zip
        }));
        setValue('zip', response.zip);
      }
      setValue('roofAge', response.roofAge);
      setValue('suiteNum', response.suiteNum);
      setValue('avgkW', response.avgkW);
      setValue('month', response.month);
      setValue('utilityCharge', response.utilityCharge);
      setValue('estimatedkWPerHour', response.estimatedkWPerHour);
      setValue('yearlyElectricityCost', response.yearlyElectricityCost);
      setValue('energySupplierId', response.energySupplierId);
      setStateData((prevState) => ({
        ...prevState,
        sliderValue: response.percentOfEnergyToGenerate
      }));
    }

    getCoolingData.fetch({ itemValueType: 0 });
    getHeatingData.fetch({ itemValueType: 1 });
    getEnergyProviderData.fetch({ itemValueType: 4 });
  }, []);

  useEffect(() => {
    const data = Object.keys(ReportData).length > 0;
    if (data) {
      if (ReportData) {
        const response = reportData;
        const coolingTypeId = response.coolingTypeId;
        const heatingTypeId = response.heatingTypeId;
        const energySupplierId = response.energySupplierId;
        const rolesData = response.roles || [];
        //@ts-expect-error TODO check this missing type
        document.getElementById('roofer').checked =
          //@ts-expect-error TODO check this missing type
          rolesData?.findIndex((ele) => ele?.roleId === 1) !== -1;
        //@ts-expect-error TODO check this missing type
        document.getElementById('electrician').checked =
          //@ts-expect-error TODO check this missing type
          rolesData?.findIndex((ele) => ele?.roleId === 2) !== -1;
        //@ts-expect-error TODO check this missing type
        document.getElementById('bearing_analysis').checked =
          //@ts-expect-error TODO check this missing type
          rolesData?.findIndex((ele) => ele?.roleId === 3) !== -1;

        if (coolingTypeId) {
          setStateData((prevState) => ({
            ...prevState,
            selectedCoolingType: coolingTypeId
          }));
          setValue('coolingTypeId', coolingTypeId);
        }

        if (heatingTypeId) {
          setStateData((prevState) => ({
            ...prevState,
            selectedCoolselectedHeatingTypeingType: heatingTypeId
          }));
          setValue('heatingTypeId', heatingTypeId);
        }

        if (energySupplierId) {
          setStateData((prevState) => ({
            ...prevState,
            selectedEnergySupplierType: energySupplierId
          }));
        }

        //@ts-expect-error TODO check this missing type
        setValue('systemTypeId', String(response.systemTypeId));

        if (response.city) {
          setStateData((prevState) => ({
            ...prevState,
            city: response.city
          }));
          setValue('city', response.city);
        }
        setValue('roles', rolesData);
        if (response.address) {
          setStateData((prevState) => ({
            ...prevState,
            address: response.address
          }));
          setValue('address', response.address);
        }
        setValue('buildingTypeId', String(response.buildingTypeId));
        setValue('percentOfEnergyToGenerate', response.percentOfEnergyToGenerate);
        if (response.state) {
          setStateData((prevState) => ({
            ...prevState,
            state: response.state
          }));
          setValue('state', response.state);
        }
        setValue('zip', response.zip);
        if (response.address) {
          setStateData((prevState) => ({
            ...prevState,
            zip: response.zip
          }));
          setValue('zip', response.zip);
        }
        setValue('roofAge', response.roofAge);
        setValue('suiteNum', response.suiteNum);
        setValue('avgkW', response.consumption[0].avgkW);
        setValue('month', response.consumption[0].month);
        setValue('utilityCharge', response.utilityCharge);
        setValue('estimatedkWPerHour', response.estimatedkWPerHour);
        setValue('yearlyElectricityCost', response.yearlyElectricityCost);
        setValue('energySupplierId', response.energySupplierId);
        setStateData((prevState) => ({
          ...prevState,
          sliderValue: response.percentOfEnergyToGenerate,
          getReportSlider: response.percentOfEnergyToGenerate,
          getReportParameter: reportParameters
        }));
      }
    }
  }, [ReportData]);

  const handleCheckboxChange = (roleId: number) => {
    const currentRoles = getValues('roles');
    const isRoleSelected = currentRoles.some((role) => role.roleId === roleId);

    const updatedRoles = isRoleSelected
      ? currentRoles.filter((role) => role.roleId !== roleId)
      : [...currentRoles, { roleId }];

    setValue('roles', updatedRoles);
  };

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="breadCrumb mt-5">
              <ul>
                <li>Basics</li>
                <span className="Icon">
                  {' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="12"
                    fill="none"
                    viewBox="0 0 7 12"
                  >
                    <path
                      fill="#A4B0C2"
                      d="M.914 0L0 .903l.457.45L5.17 6 .457 10.646l-.457.45.914.904.457-.453L7 6 1.37.453.915 0z"
                    ></path>
                  </svg>
                </span>
                <li>Panels</li>
                <span className="Icon">
                  {' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="12"
                    fill="none"
                    viewBox="0 0 7 12"
                  >
                    <path
                      fill="#A4B0C2"
                      d="M.914 0L0 .903l.457.45L5.17 6 .457 10.646l-.457.45.914.904.457-.453L7 6 1.37.453.915 0z"
                    ></path>
                  </svg>
                </span>
                <li>Permit</li>
                <span className="Icon">
                  {' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="12"
                    fill="none"
                    viewBox="0 0 7 12"
                  >
                    <path
                      fill="#A4B0C2"
                      d="M.914 0L0 .903l.457.45L5.17 6 .457 10.646l-.457.45.914.904.457-.453L7 6 1.37.453.915 0z"
                    ></path>
                  </svg>
                </span>
                <li>Billing/Financing</li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row style={{ padding: '30px 0px 60px' }}>
          <Col lg={4} md={4} sm={12}>
            <SideBar
              onSliderValueChange={(sliderValue: number) => {
                setStateData((prevState) => ({
                  ...prevState,
                  sliderValue
                }));
              }}
              getReportSlider={stateData.getReportSlider}
              getReportParameter={stateData.getReportParameter}
            />
          </Col>
          <Col lg={8} md={8} sm={12}>
            <div className="cardBorder">
              <div className="solarRight">
                <Form onSubmit={handleSubmit(formSubmit)} autoComplete="off">
                  <Row>
                    <Col lg={4} md={4} sm={12}>
                      <div className="solarRightTopbar">
                        <h6 className="text-center">Type of solar</h6>
                        <div className="flex">
                          <Form.Group as={Col} md="6" sm="6" controlId="validationCustom01">
                            <input
                              type="radio"
                              className="gridradio"
                              id="gridTied"
                              value={1}
                              {...register('systemTypeId')}
                              //@ts-expect-error TODO check this missing type
                              onChange={(e) => setValue('systemTypeId', e.target.value)}
                            />
                            <label htmlFor="gridTied">Grid-Tied</label>
                          </Form.Group>
                          <Form.Group as={Col} md="6" sm="6" controlId="validationCustom01">
                            <input
                              type="radio"
                              className="gridradio"
                              id="offGrid"
                              value={2}
                              {...register('systemTypeId')}
                              //@ts-expect-error TODO check this missing type
                              onChange={(e) => setValue('systemTypeId', e.target.value)}
                            />
                            <label htmlFor="offGrid">Off-Grid</label>
                          </Form.Group>
                        </div>
                      </div>
                    </Col>
                    <Col lg={8} md={8} sm={12}>
                      <div className="solarRightTopbar">
                        <h6 className="text-center">Work I will Perform</h6>
                        <div className="flex">
                          <Form.Group as={Col} md="3" sm="3" controlId="validationCustom01">
                            <input
                              type="checkbox"
                              id="roofer"
                              name="roleId"
                              value={1}
                              onChange={() => handleCheckboxChange(1)}
                            />
                            <label htmlFor="roofer">Roofer</label>
                          </Form.Group>
                          <Form.Group as={Col} md="3" sm="3" controlId="validationCustom01">
                            <input
                              type="checkbox"
                              id="electrician"
                              name="roleId"
                              value={2}
                              onChange={() => handleCheckboxChange(2)}
                            />
                            <label htmlFor="electrician">Electrician</label>
                          </Form.Group>
                          <Form.Group as={Col} md="6" sm="6" controlId="validationCustom01">
                            <input
                              type="checkbox"
                              id="bearing_analysis"
                              name="roleId"
                              value={3}
                              //checked={getValues("roles").includes("3")}
                              onChange={() => handleCheckboxChange(3)}
                            />
                            <label htmlFor="bearing_analysis">PE-Load-Bearing Analysis</label>
                          </Form.Group>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="alertBar">
                    <div className="alertContent">
                      <p>Save time. Autofill your current location.</p>
                      <Button className="button-medium-default fill-green" onClick={handleAutofill}>
                        Autofill
                      </Button>
                    </div>
                  </div>
                  <Form.Group as={Col} md="12" className="" controlId="formBasicEmail">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      name="address"
                      type="text"
                      placeholder="Enter address"
                      //@ts-expect-error TODO check this missing type
                      error={Boolean(errors.address)}
                      value={stateData.address}
                      onChange={(e) => {
                        const selectedAddress = e.target.value;
                        const selectedPrediction = stateData.predictions.find(
                          (prediction) => prediction.address === selectedAddress
                        );

                        if (selectedPrediction) {
                          getGeolocationDetails.fetch({
                            placeId: selectedPrediction.placeId
                          });
                        }

                        setStateData((prevState) => ({
                          ...prevState,
                          address: selectedAddress
                        }));

                        if (selectedAddress !== null) {
                          setValue('address', selectedAddress);
                        }

                        getGeolocationAutocomplete.fetch({
                          searchText: selectedAddress
                        });
                      }}
                      list="predictions"
                    />
                    <datalist id="predictions">
                      {stateData.predictions.map((prediction) => (
                        <option key={prediction.placeId} value={prediction.address} />
                      ))}
                    </datalist>
                    <p style={{ color: 'red', paddingTop: 3, fontSize: '14px' }}>
                      {errors.address?.message}
                    </p>
                  </Form.Group>
                  <Row>
                    <div className="flex" style={{ maxWidth: '400px' }}>
                      <Form.Group as={Col} md="6" sm="6" className="mt-2">
                        <Form.Control
                          type="radio"
                          className="gridradio"
                          id="singleFamily"
                          value={1}
                          {...register('buildingTypeId')}
                          onChange={(e) => setValue('buildingTypeId', e.target.value)}
                        />
                        <Form.Label htmlFor="singleFamily">Single Family</Form.Label>
                      </Form.Group>
                      <Form.Group as={Col} md="6" sm="6" className="mt-2">
                        <Form.Control
                          type="radio"
                          className="gridradio"
                          id="townHome"
                          value={2}
                          {...register('buildingTypeId')}
                          onChange={(e) => setValue('buildingTypeId', e.target.value)}
                        />
                        <Form.Label htmlFor="townHome">Townhome</Form.Label>
                      </Form.Group>
                      <Form.Group as={Col} md="6" sm="6" className="mt-2">
                        <Form.Control
                          type="radio"
                          className="gridradio"
                          id="commercial"
                          value={3}
                          {...register('buildingTypeId')}
                          onChange={(e) => setValue('buildingTypeId', e.target.value)}
                        />
                        <Form.Label htmlFor="commercial">Commercial</Form.Label>
                      </Form.Group>
                    </div>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md="4" className="mt-4">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        name="city"
                        type="text"
                        placeholder="City"
                        //@ts-expect-error TODO check this missing type
                        error={Boolean(errors.city)}
                        value={stateData.city}
                        onChange={(e) => {
                          const value = e.target.value;
                          setStateData((prevState) => ({
                            ...prevState,
                            city: value
                          }));
                          setValue('city', value);
                        }}
                      />
                      <p style={{ color: 'red', paddingTop: 3, fontSize: '14px' }}>
                        {errors.city?.message}
                      </p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="mt-4">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        name="state"
                        type="text"
                        placeholder="State"
                        //@ts-expect-error TODO check this missing type
                        error={Boolean(errors.state)}
                        value={stateData.state}
                        onChange={(e) => {
                          const value = e.target.value;
                          setStateData((prevState) => ({
                            ...prevState,
                            state: value
                          }));
                          setValue('state', value);
                        }}
                      />
                      <p style={{ color: 'red', paddingTop: 3, fontSize: '14px' }}>
                        {errors.state?.message}
                      </p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="mt-4">
                      <Form.Label>Zip</Form.Label>
                      <Form.Control
                        name="zip"
                        type="text"
                        placeholder="Zip"
                        //@ts-expect-error TODO check this missing type
                        error={Boolean(errors.zip)}
                        onChange={(e) => {
                          const zipCode = e.target.value;

                          setStateData((prevState) => ({
                            ...prevState,
                            zip: zipCode
                          }));

                          getGeolocationGeocodeZip.fetch({
                            lat: 0,
                            lon: 0,
                            zipCode
                          });

                          setValue('zip', zipCode);
                        }}
                        value={stateData.zip}
                      />
                      <p style={{ color: 'red', paddingTop: 3, fontSize: '14px' }}>
                        {errors.zip?.message}
                      </p>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md="4" className="mt-4">
                      <Form.Label>Cooling</Form.Label>
                      <div className="flex">
                        <Form.Select
                          aria-label="Default select example"
                          {...register('coolingTypeId')}
                          value={stateData.selectedCoolingType}
                          onChange={(e) => {
                            setStateData((prevState) => ({
                              ...prevState,
                              selectedCoolingType: e.target.value
                            }));
                          }}
                        >
                          <option value="">Choose Cooling Type</option>
                          {coolingData?.map((coolingType) => (
                            <option value={coolingType.id} key={coolingType.id}>
                              {coolingType.description}
                            </option>
                          ))}
                        </Form.Select>

                        <Link title="Cooling Type" id="t-1">
                          <span className="toolTip">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill="#747787"
                                d="M16 8A8 8 0 11-.001 8 8 8 0 0116 8zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 00.25.246h.811a.25.25 0 00.25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 00.241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.424.927 1.009.927z"
                              ></path>
                            </svg>
                          </span>
                        </Link>
                      </div>
                      <p style={{ color: 'red', paddingTop: 3, fontSize: '14px' }}>
                        {errors.coolingTypeId?.message}
                      </p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="mt-4">
                      <Form.Label>Heating</Form.Label>
                      <Form.Select
                        {...register('heatingTypeId')}
                        aria-label="Default select example"
                        value={stateData.selectedHeatingType}
                        onChange={(e) => {
                          setStateData((prevState) => ({
                            ...prevState,
                            selectedHeatingType: e.target.value
                          }));
                        }}
                      >
                        <option value="">Choose Heating Type</option>
                        {heatingData?.map((heatingType) => (
                          <option key={heatingType.id} value={heatingType.id}>
                            {heatingType.description}
                          </option>
                        ))}
                      </Form.Select>
                      <p style={{ color: 'red', paddingTop: 3, fontSize: '14px' }}>
                        {errors.heatingTypeId?.message}
                      </p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="mt-4">
                      <Form.Label>
                        Age of roof <small>(in years)</small>
                      </Form.Label>
                      <div>
                        <div className="flex">
                          <Form.Control type="number" placeholder="" {...register('roofAge')} />
                          <Link title="Age of roof" id="t-2">
                            <span className="toolTip">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill="#747787"
                                  d="M16 8A8 8 0 11-.001 8 8 8 0 0116 8zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 00.25.246h.811a.25.25 0 00.25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 00.241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.424.927 1.009.927z"
                                ></path>
                              </svg>
                            </span>
                          </Link>
                        </div>
                        <p style={{ color: 'red', paddingTop: 3, fontSize: '14px' }}>
                          {errors.roofAge?.message}
                        </p>
                      </div>
                    </Form.Group>
                  </Row>
                  <Row className="mt-3">
                    <Form.Group as={Col} md="4" className="mt-4">
                      <Form.Label>No of Stories</Form.Label>
                      <div>
                        <div className="flex">
                          <Form.Control
                            type="number"
                            placeholder="No of stories"
                            //@ts-expect-error TODO check this missing type
                            error={Boolean(errors.suiteNum)}
                            {...register('suiteNum')}
                          />
                          <Link title="No of Stories" id="t-3">
                            <span className="toolTip">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="none"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill="#747787"
                                  d="M16 8A8 8 0 11-.001 8 8 8 0 0116 8zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 00.25.246h.811a.25.25 0 00.25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 00.241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.424.927 1.009.927z"
                                ></path>
                              </svg>
                            </span>
                          </Link>
                        </div>
                        <p style={{ color: 'red', paddingTop: 3, fontSize: '14px' }}>
                          {errors.suiteNum?.message}
                        </p>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="mt-4">
                      <span className="subLabel">Avg. kWhrs/month</span>
                      <Form.Label>January</Form.Label>
                      <Form.Control type="number" placeholder="" {...register('avgkW')} />
                      <p style={{ color: 'red', paddingTop: 3, fontSize: '14px' }}>
                        {errors.avgkW?.message}
                      </p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="mt-4">
                      <Form.Label>August</Form.Label>
                      <Form.Control type="text" placeholder="" {...register('month')} />
                      <p style={{ color: 'red', paddingTop: 3, fontSize: '14px' }}>
                        {errors.month?.message}
                      </p>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md="4" className="mt-4">
                      <Form.Label>
                        Utility Charge <small>(cents per kWH)</small>
                      </Form.Label>
                      <Form.Control type="number" placeholder="" {...register('utilityCharge')} />
                      <p style={{ color: 'red', paddingTop: 3, fontSize: '14px' }}>
                        {errors.utilityCharge?.message}
                      </p>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="mt-4">
                      <Form.Label>Est. kWhrs Consumed</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                        //@ts-expect-error TODO check this missing type
                        error={Boolean(errors.estimatedkWPerHour)}
                        {...register('estimatedkWPerHour')}
                      />
                      <p style={{ color: 'red', paddingTop: 3, fontSize: '14px' }}>
                        {errors.estimatedkWPerHour?.message}
                      </p>

                      <div className="flex mt-3">
                        <button className="btn-secondary-default">Restore Original Value</button>
                        <Link title="Restore original value" id="t-t">
                          <span className="toolTip">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill="#747787"
                                d="M16 8A8 8 0 11-.001 8 8 8 0 0116 8zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 00.25.246h.811a.25.25 0 00.25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 00.241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.424.927 1.009.927z"
                              ></path>
                            </svg>
                          </span>
                        </Link>
                      </div>
                    </Form.Group>
                    <Form.Group as={Col} md="4" className="mt-4">
                      <Form.Label>Yearly Electricity Cost</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                        //@ts-expect-error TODO check this missing type
                        error={Boolean(errors.yearlyElectricityCost)}
                        {...register('yearlyElectricityCost')}
                      />
                      <p style={{ color: 'red', paddingTop: 3, fontSize: '14px' }}>
                        {errors.yearlyElectricityCost?.message}
                      </p>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md="4" className="mt-4">
                      <Form.Label>Energy Provider</Form.Label>
                      <div className="flex">
                        <Form.Select
                          aria-label="Default select example"
                          {...register('energySupplierId')}
                          value={stateData.selectedEnergySupplierType}
                          onChange={(e) => {
                            setStateData((prevState) => ({
                              ...prevState,
                              selectedEnergySupplierType: e.target.value
                            }));
                          }}
                        >
                          <option value="">Choose Energy Provider</option>
                          {energyProviderData?.map((energyProvider) => (
                            <option value={energyProvider.id} key={energyProvider.id}>
                              {energyProvider.description}
                            </option>
                          ))}
                        </Form.Select>

                        <Link title="Energy Provider" id="t-1">
                          <span className="toolTip">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill="#747787"
                                d="M16 8A8 8 0 11-.001 8 8 8 0 0116 8zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 00.25.246h.811a.25.25 0 00.25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 00.241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.424.927 1.009.927z"
                              ></path>
                            </svg>
                          </span>
                        </Link>
                      </div>
                      <p style={{ color: 'red', paddingTop: 3, fontSize: '14px' }}>
                        {errors.energySupplierId?.message}
                      </p>
                    </Form.Group>

                    <Form.Group as={Col} md="8" className="mt-4">
                      <div className="flex">
                        <Form.Label>Net Metering Policy</Form.Label>
                        <Link title="Metering Policy" id="t-4">
                          <span className="toolTip">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill="#747787"
                                d="M16 8A8 8 0 11-.001 8 8 8 0 0116 8zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 00.25.246h.811a.25.25 0 00.25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 00.241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.424.927 1.009.927z"
                              ></path>
                            </svg>
                          </span>
                        </Link>
                      </div>
                      <Form.Select aria-label="Default select example">
                        <option>Choose Metering Policy</option>
                        <option value="Excess power purchased at full retail price" selected>
                          Excess power purchased at full retail price
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Button variant="primary" className="w-100 text-center" type="submit">
                        Save Changes
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
