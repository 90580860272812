import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { AnyRecord } from '@app-types/general';
import { getEndpoint } from '@utils/api';

export const useAddSolarDesign = (): {
  addSolarDesign: FetchResource<AnyRecord, AnyRecord>;
} => {
  const fetch = useFetch<AnyRecord>();

  return {
    addSolarDesign: {
      data: fetch[0],
      status: fetch[1],
      fetch: (data, options = {}) => {
        fetch[2](
          {
            method: 'post',
            url: getEndpoint({
              path: '/design/post-solar-design'
            }),
            data
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
