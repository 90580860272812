import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { getEndpoint } from '@utils/api';

type UseGetComponentGetComponentsByTypeResult = Array<{
  description: string;
  id: string;
}>;

export const useGetComponentsByType = (): {
  getComponentsByType: FetchResource<void, UseGetComponentGetComponentsByTypeResult>;
} => {
  const fetch = useFetch<UseGetComponentGetComponentsByTypeResult>();

  return {
    getComponentsByType: {
      data: fetch[0],
      status: fetch[1],
      fetch: (_, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({
              path: '/component/get-components-by-type',
              query: { type: 'PV' }
            })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
