import {
  GoogleMap,
  GroundOverlay,
  Marker,
  PolygonF,
  Polyline,
  StandaloneSearchBox,
  useJsApiLoader
} from '@react-google-maps/api';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { IoIosArrowUp } from 'react-icons/io';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

import './index.css';
import { useGetComponentsByType } from '@api/component/useGetComponentsByType';
import { useComputeTilesPosition } from '@api/design/useComputeTilesPosition';
import { useGetDataLayer } from '@api/map/useGetDataLayer';
import { AnyRecord } from '@app-types/general';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Box,
  Button,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { isNumber } from '@utils/general';
import { useFormik } from 'formik';
import { fromArrayBuffer } from 'geotiff';
import geokeysToProj4 from 'geotiff-geokeys-to-proj4';
import proj4 from 'proj4';
import * as Yup from 'yup';

interface Coordinates {
  lat: number;
  lng: number;
}

interface Mask {
  rasters: Array<Array<number>>;
  width: number;
  height: number;
}

export const Map = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyB5fF9C_VlMQmTOIrK4_bgCzwEukEHqsuE',
    libraries: ['places', 'geometry']
  });

  const initialCenter: Coordinates = { lat: 40.688386661759274, lng: -73.93344666237486 };

  const [markers, setMarkers] = useState<Array<Coordinates>>([]);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();
  const [polygonPaths, setPolygonPaths] = useState<Array<Array<Coordinates>>>([]);
  const [searchedLocationMarker, setSearchedLocationMarker] = useState<Coordinates>();
  const [center, setCenter] = useState<Coordinates | undefined>(initialCenter);
  const [panelOrientation, setPanelOrientation] = useState(0);
  const [isPanelButtonClicked, setIsPanelButtonClicked] = useState(false);
  const [polygonStartPoints, setPolygonStartPoints] = useState<Array<Coordinates>>([]);
  const geometryLibrary = useRef<google.maps.GeometryLibrary>();
  const [ridgeLinePoints, setRidgeLinePoints] = useState<Array<Coordinates>>([]);
  const [isRidgeLineButtonClicked, setIsRidgeLineButtonClicked] = useState(false);
  const [selectAreaClicked, setSelectAreaClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [geoTiffData, setGeoTiffData] = useState<{ url?: string }>({});
  const [bound, setBound] = useState<google.maps.LatLngBoundsLiteral>();
  const [googleApi, setGoogleApi] = useState<typeof google | null>(null); // Hold google API when loaded
  const [isCollapsed, setIsCollapsed] = useState(false);

  const isDropdownArrow = useMediaQuery('(min-width:460px)');

  const selectedMonth = 5
  /**
   * Get Data Layer
   */
  const { getDataLayer } = useGetDataLayer();
  const irradianceData = getDataLayer.data?.result;

  /**
   * Get Design Compute Tiles Position
   */
  const { computeTilesPosition } = useComputeTilesPosition();
  const panelPlacingData = computeTilesPosition.data?.result;

  /**
   * Get Component Get Components By Type
   */
  const { getComponentsByType } = useGetComponentsByType();
  const ModalDropdownItems = getComponentsByType.data?.result;

  const panelSchema = Yup.object().shape({
    panelAmount: Yup.number()
      .required('Panel is Required')
      .typeError('Panel must be a number')
      .positive('Panel must be a positive number')
      .integer('Panel must be an integer'),
    panelSeperation: Yup.number()
      .required('Panel Seperation is Required')
      .typeError('Panel Separation must be a number')
      .positive('Panel Separation must be a positive number')
  });

  // Check for google being loaded properly
  useEffect(() => {
    if (window.google && window.google.maps) {
      setGoogleApi(window.google);
    }
  }, [isLoaded]); // Run this effect when isLoaded changes

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      panelModal: ModalDropdownItems && ModalDropdownItems[0]?.description,
      panelAmount: '',
      panelSeperation: ''
    },
    validationSchema: panelSchema,
    onSubmit: async (values) => {
      if (googleApi && googleApi.maps && googleApi.maps.geometry) {
        // const spherical = googleApi.maps.geometry.spherical;
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(async (position) => {
            if (searchedLocationMarker) {
              const lat = searchedLocationMarker.lat;
              const lon = searchedLocationMarker.lng;

              computeTilesPosition.fetch({
                orientationSegment: [
                  {
                    lat: ridgeLinePoints[0].lat,
                    lon: ridgeLinePoints[0].lng
                  },
                  {
                    lat: ridgeLinePoints[1].lat,
                    lon: ridgeLinePoints[1].lng
                  }
                ],
                roofArea: polygonPaths[0]?.map((poligonPath) => {
                  return {
                    lat: poligonPath.lat,
                    lon: poligonPath.lng
                  };
                }),
                pvId: ModalDropdownItems?.find((val) => val.description === values.panelModal)?.id,
                panelsAmount: Number(values.panelAmount),
                panelsOrientation: panelOrientation === 0 ? 0 : 1,
                panelsSeparation: Number(values.panelSeperation),
                location: {
                  lat: lat,
                  lon: lon
                }
              });
              showSolarPotential();
            } else {
              const lat = position.coords.latitude;
              const lon = position.coords.longitude;

              computeTilesPosition.fetch({
                orientationSegment: [
                  {
                    lat: ridgeLinePoints[0].lat,
                    lon: ridgeLinePoints[0].lng
                  },
                  {
                    lat: ridgeLinePoints[1].lat,
                    lon: ridgeLinePoints[1].lng
                  }
                ],
                roofArea: polygonPaths[0]?.map((_val, index) => {
                  return {
                    lat: polygonPaths[0][index].lat,
                    lon: polygonPaths[0][index].lng
                  };
                }),
                pvId: ModalDropdownItems?.find((val) => val.description === values.panelModal)?.id,
                panelsAmount: Number(values.panelAmount),
                panelsOrientation: panelOrientation === 0 ? 0 : 1,
                panelsSeparation: Number(values.panelSeperation),
                location: {
                  lat: lat,
                  lon: lon
                }
              });

              showSolarPotential();
            }
          });
        }
      }
    }
  });

  const { handleSubmit, touched, errors, setFieldValue, setFieldTouched } = formik;

  const handleSaveButtonClick = () => {
    const paths = markers.map((marker) => ({ lat: marker.lat, lng: marker.lng }));
    setPolygonPaths([...polygonPaths, paths]);
    setMarkers([]);
  };

  const handleRidgeLineButtonClick = () => {
    if (isRidgeLineButtonClicked == true) {
      setRidgeLinePoints([]);
      setIsRidgeLineButtonClicked(false);
    } else {
      setIsRidgeLineButtonClicked(true);
    }
  };

  const handlePlacePanelButtonClick = () => {
    handleSubmit();
    setIsPanelButtonClicked(false);
    setTimeout(() => {
      setIsPanelButtonClicked(true);
      if (markers.length > 0) {
        const bottomLeftPoint = getBottomLeftPoint(markers);
        setPolygonStartPoints([
          ...polygonStartPoints,
          { lat: bottomLeftPoint.lat, lng: bottomLeftPoint.lng }
        ]);
      }
      // setIsPanelButtonClicked(false);
    }, 500);
  };

  const handleClearButton = () => {
    setMarkers([]);
    setPolygonPaths([]);
    setPolygonStartPoints([]);
    setRidgeLinePoints([]);
    setIsRidgeLineButtonClicked(false);
    if (panelPlacingData?.length > 0 || polygonPaths?.[0]?.length > 0) {
      setFieldValue('panelAmount', '');
      setFieldValue('panelSeperation', '');
      setFieldTouched('panelAmount', false);
      setFieldTouched('panelSeperation', false);
      setPanelOrientation(0);
    }
    setMap(null);
    setCenter(undefined);
    setIsPanelButtonClicked(false);

    computeTilesPosition.reset();

    /**
     * TODO Cupull does not know what this is for
     */
    // setMap({
    //   options: {
    //     strokeColor: 'rgba(176, 190, 197, 0.5)',
    //     strokeOpacity: 0.9,
    //     strokeWeight: 1,
    //     fillColor: 'rgba(176, 190, 197, 0.5)',
    //     fillOpacity: 0.9
    //   }
    // });
  };

  // When panelbuttonclicked or API data like panelPlacing data changes showSolarPotential is triggered
  useEffect(() => {
    if (googleApi && googleApi.maps && googleApi.maps.geometry) {
      /**
       * TODO Cupull does not know what this is for
       */
      // const spherical = googleApi.maps.geometry.spherical;
      geometryLibrary.current = googleApi.maps.geometry;
      if (isPanelButtonClicked || panelPlacingData) {
        showSolarPotential();
      }
    }
  }, [isPanelButtonClicked, panelPlacingData]);

  useEffect(() => {
    getComponentsByType.fetch();
  }, []);

  const onLoad = React.useCallback(
    function callback(map: google.maps.Map) {
      if (googleApi && googleApi.maps) {
        const bounds = new googleApi.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
      }
    },
    [googleApi, center]
  );

  const onPlacesChanged = async () => {
    const newPlaces = searchBox?.getPlaces();

    if (newPlaces && newPlaces.length > 0 && map) {
      const place = newPlaces[0];
      const lat = place.geometry?.location?.lat();
      const lng = place.geometry?.location?.lng();

      
      if (isNumber(lat) && isNumber(lng)) {
        setCenter({ lat, lng });
        setSearchedLocationMarker({ lat, lng });
      }
    }
  };

  // To clear all panels and area when location changes
  useEffect(() => {
    setPolygonPaths([]);
    setRidgeLinePoints([]);

    computeTilesPosition.reset();

    setFieldValue('panelAmount', '');
    setFieldValue('panelSeperation', '');
    setFieldTouched('panelAmount', false);
    setFieldTouched('panelSeperation', false);
    setGeoTiffData({});
    setPanelOrientation(0);
  }, [searchedLocationMarker?.lat, searchedLocationMarker?.lng]);

  const searchedLocationIcon = {
    url: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png'
  };

  const getBottomLeftPoint = (points: Array<Coordinates>) => {
    // Initialize the bottom left point as the first point in the array
    let bottomLeftPoint = points[0];

    // Iterate through the array of points
    for (let i = 1; i < points.length; i++) {
      if (
        points[i].lat < bottomLeftPoint.lat ||
        (points[i].lat === bottomLeftPoint.lat && points[i].lng < bottomLeftPoint.lng)
      ) {
        bottomLeftPoint = points[i];
      }
    }

    return bottomLeftPoint;
  };

  const getBoundingBox = (coords: Array<Coordinates>) => {
    const lats = coords.map((coord) => coord.lat);
    const lngs = coords.map((coord) => coord.lng);
    return {
      minLat: Math.min(...lats),
      maxLat: Math.max(...lats),
      minLng: Math.min(...lngs),
      maxLng: Math.max(...lngs)
    };
  };

  const showSolarPotential = () => {
    // const paths = generatePathsComesFromApi();

    /**
     * TODO Cupull does not know what this is for
     */
    // setMap({
    //   paths,
    //   options: {
    //     strokeColor: '#B0BEC5',
    //     strokeOpacity: 0.9,
    //     strokeWeight: 5,
    //     fillColor: 'black',
    //     fillOpacity: 0.9
    //   }
    // });
    setMarkers([]);
  };

  const handleSelectArea = () => {
    if (selectAreaClicked) {
      setSelectAreaClicked(false);
      handleSaveButtonClick();
    } else {
      setSelectAreaClicked(true);
      handleClearButton();
      setBound(undefined);
      setGeoTiffData({});
    }
  };

  const getBoundingBoxCorners = async (coordinates: Array<Coordinates>) => {
    return new Promise((resolve, reject) => {
      try {
        if (!coordinates || coordinates.length === 0) {
          throw new Error('No coordinates provided');
        }

        // Flatten the array if coordinates is an array of arrays
        const flatCoordinates = coordinates.flat();

        // Initialize min and max values using the first coordinate
        let minLat = flatCoordinates[0].lat;
        let maxLat = flatCoordinates[0].lat;
        let minLng = flatCoordinates[0].lng;
        let maxLng = flatCoordinates[0].lng;

        // Iterate over the coordinates to find the min/max latitude and longitude
        flatCoordinates.forEach((coord) => {
          if (coord.lat < minLat) minLat = coord.lat;
          if (coord.lat > maxLat) maxLat = coord.lat;
          if (coord.lng < minLng) minLng = coord.lng;
          if (coord.lng > maxLng) maxLng = coord.lng;
        });

        // NE and SW corners
        const ne = { lat: maxLat, lng: maxLng };
        const sw = { lat: minLat, lng: minLng };

        resolve({ ne, sw });
      } catch (error) {
        reject(error);
      }
    });
  };

  const getCenterPoint = (coords: Array<Coordinates>) => {
    const latSum = coords.reduce((sum, coord) => sum + coord.lat, 0);
    const lonsum = coords.reduce((sum, coord) => sum + coord.lng, 0);
    const count = coords.length;
    return {
      lat: latSum / count,
      lon: lonsum / count
    };
  };

  const handleIrradianceMap = async () => {
    const boundingBox = getBoundingBox(polygonPaths[0]);
    let { minLat, maxLat, minLng, maxLng } = boundingBox;
    // Adjust the bounding box to ensure it covers the whole house
    const adjustmentFactor = 0.0001; // Adjust this factor to fine-tune the area covered

    minLat -= adjustmentFactor;
    maxLat += adjustmentFactor;
    minLng -= adjustmentFactor;
    maxLng += adjustmentFactor;

    setBound({ north: maxLat, south: minLat, west: minLng, east: maxLng });
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            //@ts-expect-error TODO fix this type later
            const { ne, sw } = await getBoundingBoxCorners(polygonPaths);

            if (
              googleApi &&
              googleApi.maps &&
              googleApi.maps.geometry &&
              googleApi.maps.geometry.spherical
            ) {
              const neLatLng = new googleApi.maps.LatLng(ne.lat, ne.lng);
              const swLatLng = new googleApi.maps.LatLng(sw.lat, sw.lng);

              const diameter = googleApi.maps.geometry.spherical.computeDistanceBetween(
                neLatLng,
                swLatLng
              );

              const radius = Math.ceil(diameter / 2);
              if (searchedLocationMarker) {
                const { lat, lon } = getCenterPoint(polygonPaths[0]);

                getDataLayer.fetch({
                  lat,
                  lon,
                  radius
                });
              } else {
                const lat = position.coords.latitude;
                const lon = position.coords.longitude;

                getDataLayer.fetch({
                  lat,
                  lon,
                  radius
                });
              }
            } else {
              console.error('Google Maps Geometry library is not loaded');
            }
          } catch (error) {
            //@ts-expect-error TODO fix this type later
            console.error('Error getting bounding box corners:', error.message);
          }
        },
        (error) => {
          console.error('Error getting location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not supported by your browser');
    }
  };

  // const handleInfoWindowClose = () => {
  //   console.log("handleInfoWindowClose");
  // }

  function createPalette(hexColors: Array<string>) {
    // Map each hex color into an RGB value.
    const rgb = hexColors.map(colorToRGB);
    // Create a palette with 256 colors derived from our rgb colors.
    const size = 256;
    const step = (rgb.length - 1) / (size - 1);
    return Array(size)
      .fill(0)
      .map((_, i) => {
        // Get the lower and upper indices for each color.
        const index = i * step;
        const lower = Math.floor(index);
        const upper = Math.ceil(index);
        // Interpolate between the colors to get the shades.
        return {
          r: lerp(rgb[lower].r, rgb[upper].r, index - lower),
          g: lerp(rgb[lower].g, rgb[upper].g, index - lower),
          b: lerp(rgb[lower].b, rgb[upper].b, index - lower)
        };
      });
  }

  function colorToRGB(color: string) {
    const hex = color.startsWith('#') ? color.slice(1) : color;
    return {
      r: parseInt(hex.substring(0, 2), 16),
      g: parseInt(hex.substring(2, 4), 16),
      b: parseInt(hex.substring(4, 6), 16)
    };
  }

  function lerp(x: number, y: number, t: number) {
    return x + t * (y - x);
  }

  function normalize(value: number, max: number, min: number) {
    return (value - min) / (max - min);
  }

  function renderRGB(rgb: Mask, mask: Mask | undefined) {
    const canvas = document.createElement('canvas');
    
    canvas.width = mask ? mask.width : rgb.width;
    canvas.height = mask ? mask.height : rgb.height;

    const dw = rgb.width / canvas.width;
    const dh = rgb.height / canvas.height;

    const ctx = canvas.getContext('2d');

    if (ctx) {
      const img = ctx.getImageData(0, 0, canvas.width, canvas.height);

      if (img) {
        for (let y = 0; y < canvas.height; y++) {
          for (let x = 0; x < canvas.width; x++) {
            const rgbIdx = Math.floor(y * dh) * rgb.width + Math.floor(x * dw);
            const maskIdx = y * canvas.width + x;

            const imgIdx = y * canvas.width * 4 + x * 4;
            img.data[imgIdx + 0] = rgb.rasters[0][rgbIdx];
            img.data[imgIdx + 1] = rgb.rasters[1][rgbIdx];
            img.data[imgIdx + 2] = rgb.rasters[2][rgbIdx];
            img.data[imgIdx + 3] = mask ? mask.rasters[0][maskIdx] * 255 : 255;
          }
        }

        ctx.putImageData(img, 0, 0);
      }
    }

    return canvas;
  }

  const renderPalette = async ({
    data,
    mask,
    colors,
    min,
    max,
    index
  }: {
    data: Mask;
    mask: Mask | undefined;
    colors: Array<string>;
    min: number;
    max: number;
    index: number;
  }) => {
    const palette = createPalette(colors);
    const indices = data?.rasters[index]
      .map((x) => normalize(x, max, min))
      .map((x) => Math.round(x * (palette.length - 1)));

    return renderRGB(
      {
        ...data,
        rasters: [
          indices.map((i) => palette[i].r),
          indices.map((i) => palette[i].g),
          indices.map((i) => palette[i].b)
        ]
      },
      mask
    );
  };

  const downloadGeoTIFF = async (url: string) => {
    const solarUrl = url?.includes('solar.googleapis.com')
      ? `${url}&key=${'AIzaSyB5fF9C_VlMQmTOIrK4_bgCzwEukEHqsuE'}`
      : url;
    const response = await fetch(solarUrl);
    if (response.status !== 200) {
      const error = await response.json();
      console.error(`downloadGeoTIFF failed: ${url}\n`, error);
      throw new Error(error);
    }
    const arrayBuffer = await response.arrayBuffer();
    const tiff = await fromArrayBuffer(arrayBuffer);
    const image = await tiff.getImage();
    const rasters = await image.readRasters();

    const geoKeys = image.getGeoKeys();
    const projObj = geokeysToProj4.toProj4(geoKeys);
    const projection = proj4(projObj.proj4, 'WGS84');
    const box = image.getBoundingBox();

    const sw = projection.forward({
      x: box[0] * projObj.coordinatesConversionParameters.x,
      y: box[1] * projObj.coordinatesConversionParameters.y
    });
    const ne = projection.forward({
      x: box[2] * projObj.coordinatesConversionParameters.x,
      y: box[3] * projObj.coordinatesConversionParameters.y
    });

    return {
      width: rasters.width,
      height: rasters.height,
      //@ts-expect-error TODO fix this later
      rasters: [...Array(rasters.length).keys()].map((i) => Array.from(rasters[i])),
      bounds: {
        north: ne.y,
        south: sw.y,
        east: ne.x,
        west: sw.x
      }
    };
  };

  useEffect(() => {
    const fetchAndRenderGeoTIFF = async () => {
      if (irradianceData?.monthlyFluxUrl && irradianceData?.maskUrl) {
        // const maskData = await downloadGeoTIFF(irradianceData.maskUrl);
        const monthlyFluxUrl = await downloadGeoTIFF(irradianceData.monthlyFluxUrl);

        const renderPaletteData = await renderPalette({
          data: monthlyFluxUrl,
          mask: undefined,
          colors: ['00000A', '91009C', 'E64616', 'FEB400', 'FFFFF6'],
          min: 0,
          max: 200,
          index: selectedMonth
        });

        const dataUrl = renderPaletteData.toDataURL();
        setGeoTiffData({
          ...geoTiffData,
          url: dataUrl
        });
      }
    };

    fetchAndRenderGeoTIFF();
  }, [irradianceData?.monthlyFluxUrl, selectedMonth]);

  const overlayInstance = useRef<google.maps.GroundOverlay | null>(null);

  // To clear the existing Groundoverlay
  useEffect(() => {
    if (!bound || Object.keys(bound).length === 0 || !geoTiffData?.url) {
      if (overlayInstance.current) {
        overlayInstance.current.setMap(null);
        overlayInstance.current = null;
      }
    }
  }, [bound, geoTiffData, map]);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    // <div style={{ position: 'relative', height: '130vh' }}></div>
    <div style={{ position: 'relative', height: '100vh' }}>
      <div
        style={{
          position: 'absolute',
          top: '8%',
          zIndex: '1',
          display: 'flex',
          alignItems: 'center',
          transform: 'translate(-50%, -50%)',
          left: '50%',
          padding: '6px 11px'
        }}
      >
        {/* <Button style={{ height: '45px',backgroundColor:'white',marginRight: '10px' }} onClick={handleSaveButtonClick}>Save</Button>
      <Button style={{ height: '45px',backgroundColor:'white',marginRight: '10px' }} onClick={handleRidgeLineButtonClick}>{ridgeLinePoints.length>1 ? 'Clear Ridge Line' : 'Ridge Line'}</Button>
      <Button style={{ height: '45px', backgroundColor:'white',marginRight: '10px' }} onClick={handlePlacePanelButtonClick}>Place Panels</Button>
      <Button style={{ height: '45px', backgroundColor:'white',marginRight: '10px' }} onClick={handleClearButton}>Clear Polygon</Button> */}
        {/* <Input 
      type="number" 
      style={{ height: '45px', backgroundColor:'white',marginRight: '10px' }}
      value={azimuthDegrees} 
      onChange={handleAzimuthChange} 
      placeholder="Azimuth" 
      sx={{ color: 'blue' }}
    /> */}
        {/* <RadioGroup style = {{marginLeft: '10px'}}
       value={panelOrientation.toString()} 
       onChange={(event) => {
       const value = parseInt(event.target.value); 
        if (value === 0 || value === 90) {
          setPanelOrientation(value);
        }
       }}
      // style={{ marginTop: '10px',backgroundColor:'white' }}
      style = {{
        backgroundColor: 'white',
        // marginRight: '152px',
        display: 'contents',
      }}
     >
      <FormControlLabel
        value="0"
        control={<Radio color="primary" />}
        label="Portrait"
        style={{ marginRight: '10px' , marginLeft: '10px',backgroundColor:'white',padding:'5px' }}
      />
      <FormControlLabel
        value="90"
        control={<Radio color="primary"/>}
        label="Landscape"
        style={{ marginRight: '13px',backgroundColor:'white',padding:'5px' }}
      />
      </RadioGroup> */}
      </div>
      <div style={{ height: '100%', width: '100%' }}>
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <GoogleMap
            mapContainerStyle={{ height: '100%', width: '100%' }}
            center={center}
            mapTypeId="satellite"
            tilt={0}
            onLoad={onLoad}
            onClick={(e: AnyRecord) => {
              if (isRidgeLineButtonClicked && ridgeLinePoints.length < 2) {
                setRidgeLinePoints([
                  ...ridgeLinePoints,
                  { lat: e.latLng.lat(), lng: e.latLng.lng() }
                ]);
              } else {
                if (selectAreaClicked) {
                  setMarkers([...markers, { lat: e.latLng.lat(), lng: e.latLng.lng() }]);
                }
              }
            }}
            //@ts-expect-error TODO check this missing type
            compassEnabled={true}
          >
            {bound && Object.keys(bound).length > 0 && geoTiffData?.url ? (
              <>
                <GroundOverlay
                  url={geoTiffData?.url}
                  bounds={bound}
                  options={{
                    opacity: 0.5,
                    clickable: false
                  }}
                  onLoad={(overlay) => {
                    if (overlayInstance.current) {
                      // Clear any existing overlay before setting a new one
                      overlayInstance.current.setMap(null);
                    }
                    overlayInstance.current = overlay;
                  }}
                />
              </>
            ) : null}
            <Box
              sx={{
                width: {
                  xs: '45%', // 60% width for extra small screens
                  sm: '45%', // 45% width for small screens
                  md: '30%', // 30% width for medium screens
                  lg: '16%' // 16% width for large screens
                },
                height: {
                  xs: isCollapsed ? '500px' : '90px',
                  sm: isCollapsed ? '700px' : '70px', // Fixed height for small screens
                  md: isCollapsed ? '700px' : '70px', // Fixed height for medium screens
                  lg: isCollapsed ? '840px' : '70px' // Fixed height for large screens
                },
                padding: '22px',
                marginTop: {
                  xs: '110px',
                  sm: '110px',
                  md: '110px',
                  lg: '110px'
                },
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                gap: '9px',
                position: 'relative',
                zIndex: 5,
                marginLeft: {
                  xs: '5px',
                  sm: '10px',
                  md: '10px',
                  lg: '10px'
                },
                transition: 'max-height 0.3s ease, height 0.3s ease', // Add transition for smooth effect
                borderRadius: '10px',
                overflowY: isCollapsed ? 'auto' : 'hidden'
              }}
            >
              {/* Building Address Section */}
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={10} sm={11}>
                  <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Segoe UI' }}>
                    Building address
                  </Typography>
                </Grid>
                <Grid item xs={2} sm={1}>
                  <Button
                    onClick={handleCollapseToggle}
                    className="toggle-button"
                    sx={{
                      marginLeft: {
                        xs: '-10px',
                        sm: '-30px',
                        md: '-30px',
                        lg: '-30px'
                      },
                      marginTop: {
                        xs: '-10px',
                        lg: '-5px'
                      }
                    }}
                  >
                    {isCollapsed ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                  </Button>
                </Grid>
              </Grid>
              {isCollapsed && (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <StandaloneSearchBox
                        onLoad={(ref) => {
                          setSearchBox(ref);
                        }}
                      >
                        <TextField
                          type="text"
                          placeholder="Building address"
                          fullWidth
                          sx={{
                            width: {
                              xs: '100%',
                              sm: '100%',
                              md: '100%',
                              lg: '100%'
                            },
                            '& input::placeholder': {
                              fontSize: {
                                xs: '12px', // Smaller size for extra small screens
                                sm: '16px', // Default size for small screens
                                md: '16px', // Default size for medium screens
                                lg: '15px' // Larger size for large screens
                              }
                            }
                          }}
                        />
                      </StandaloneSearchBox>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '3px' }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onPlacesChanged()}
                        sx={{
                          backgroundColor: '#0062cc',
                          fontFamily: 'Segoe UI',
                          color: 'white',
                          padding: '5px',
                          border: 'none',
                          borderRadius: '20px',
                          fontSize: '16px',
                          width: {
                            xs: '40%', // Adjust width for small screens
                            sm: '30%', // Maintain consistent width across medium and large screens
                            md: '40%',
                            lg: '35%'
                          }
                        }}
                      >
                        Go
                      </Button>
                    </Grid>
                  </Grid>
                  <Divider
                    sx={{
                      backgroundColor: '#007BFF',
                      height: '2px'
                    }}
                  />

                  {/* Panels Features Section */}
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontSize: '17px', fontFamily: 'Segoe UI' }}
                  >
                    Panels features
                  </Typography>

                  {/* Model Dropdown */}
                  <InputLabel
                    sx={{
                      fontFamily: 'Segoe UI',
                      padding: '18px',
                      marginTop: '-20px',
                      marginLeft: '-17px'
                    }}
                  >
                    Model:
                  </InputLabel>
                  <div style={{ position: 'relative', width: '100%', zIndex: 1 }}>
                    <select
                      className="responsive-select"
                      onChange={(e) => setFieldValue('panelModal', e.target.value)}
                      onFocus={() => setIsOpen(true)}
                      onBlur={() => setIsOpen(false)}
                      style={{
                        width: '100%',
                        padding: '10px',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                        fontFamily: 'Segoe UI',
                        zIndex: 2,
                        position: 'relative',
                        overflow: 'hidden'
                      }}
                    >
                      {ModalDropdownItems?.map((items) => (
                        <option key={items.id} value={items.description}>
                          {items.description}
                        </option>
                      ))}
                    </select>
                    <span
                      style={{
                        position: 'absolute',
                        top: '50%',
                        right: '10px',
                        transform: 'translateY(-50%)',
                        pointerEvents: 'none',
                        zIndex: 3 // Ensure the arrow is on top of the select
                      }}
                    >
                      {isOpen && isDropdownArrow ? (
                        <IoIosArrowUp />
                      ) : (
                        isDropdownArrow && <MdOutlineKeyboardArrowDown />
                      )}
                    </span>
                  </div>

                  {/* Panels Amount and Separation Fields */}
                  <TextField
                    placeholder="Panels amount..."
                    variant="outlined"
                    {...formik.getFieldProps('panelAmount')}
                    size="small"
                    onChange={(e) => {
                      setFieldValue('panelAmount', e.target.value);
                    }}
                    sx={{
                      width: '60%',
                      fontFamily: 'Segoe UI',
                      '& input::placeholder': {
                        fontSize: {
                          xs: '12px', // Smaller size for extra small screens
                          sm: '16px', // Default size for small screens
                          md: '16px', // Default size for medium screens
                          lg: '15px' // Larger size for large screens
                        }
                      }
                    }}
                    // error={Boolean(touched?.panelAmount && errors?.panelAmount)}
                    // helperText={touched?.panelAmount && errors?.panelAmount}
                  />
                  <div style={{ color: 'red', marginLeft: '5px' }}>
                    {touched.panelAmount && errors.panelAmount && errors.panelAmount}
                  </div>
                  <TextField
                    placeholder="Panels separation..."
                    variant="outlined"
                    size="small"
                    {...formik.getFieldProps('panelSeperation')}
                    onChange={(e) => {
                      setFieldValue('panelSeperation', e.target.value);
                    }}
                    sx={{
                      width: '70%',
                      fontFamily: 'Segoe UI',
                      '& input::placeholder': {
                        fontSize: {
                          xs: '12px', // Smaller size for extra small screens
                          sm: '16px', // Default size for small screens
                          md: '16px', // Default size for medium screens
                          lg: '15px' // Larger size for large screens
                        }
                      }
                    }}
                  />
                  <div style={{ color: 'red', marginLeft: '5px' }}>
                    {touched.panelSeperation && errors.panelSeperation && errors.panelSeperation}
                  </div>
                  {/* Orientation Radio Buttons */}
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <Typography variant="subtitle1" sx={{ fontFamily: 'Segoe UI' }}>
                        Orientation:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        spacing={1}
                        sx={{ marginLeft: '18px', fontFamily: 'Segoe UI' }}
                      >
                        <RadioGroup
                          row
                          value={panelOrientation.toString()} // Convert the state to string for comparison
                          onChange={(event) => setPanelOrientation(parseInt(event.target.value))} // Update state on change
                          sx={{ fontFamily: 'Segoe UI', display: 'flex' }}
                        >
                          <Grid item sx={{ mt: -3 }}>
                            <FormControlLabel value="0" control={<Radio />} label="Portrait" />
                          </Grid>
                          <Grid item sx={{ mt: -3 }}>
                            <FormControlLabel value="90" control={<Radio />} label="Landscape" />
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Divider */}
                  <Divider
                    sx={{
                      backgroundColor: '#007BFF',
                      whiteSpace: 'nowrap', // Prevent text from wrapping
                      overflow: 'hidden', // Hide overflow if any
                      textOverflow: 'ellipsis', // Add ellipsis if text is too long
                      height: '2px'
                    }}
                  />
                  {/* Actions Section */}
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontSize: '17px', fontFamily: 'Segoe UI' }}
                  >
                    Actions
                  </Typography>
                  {/* Action Buttons */}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSelectArea}
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      height: {
                        xs: '70px',
                        sm: '50px',
                        md: '60px',
                        lg: '70px'
                      },
                      backgroundColor: '#0062cc',
                      fontFamily: 'Segoe UI',
                      color: 'white',
                      border: 'none',
                      borderRadius: '20px',
                      fontSize: {
                        xs: '11px',
                        sm: '15px',
                        md: '16px',
                        lg: '16px'
                      },
                      width: '100%',
                      textTransform: 'none'
                    }}
                  >
                    {markers.length > 0 ? 'Finish Area' : 'Select Area'}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleRidgeLineButtonClick}
                    color="primary"
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      backgroundColor: '#0062cc',
                      fontFamily: 'Segoe UI',
                      color: 'white',
                      border: 'none',
                      borderRadius: '20px',
                      fontSize: {
                        xs: '11px',
                        sm: '15px',
                        md: '16px',
                        lg: '16px'
                      },
                      width: '100%',
                      textTransform: 'none'
                    }}
                  >
                    {ridgeLinePoints.length > 1 ? 'Clear direction' : 'Set direction'}
                  </Button>
                  {!geoTiffData.url ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleIrradianceMap}
                      disabled={
                        polygonPaths.length === 0 ||
                        polygonPaths[0].length === 0 ||
                        polygonPaths[0].length === 1 ||
                        polygonPaths[0].length === 2
                      }
                      sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        backgroundColor: '#0062cc',
                        fontFamily: 'Segoe UI',
                        color: 'white',
                        border: 'none',
                        borderRadius: '20px',
                        fontSize: {
                          xs: '11px',
                          sm: '15px',
                          md: '16px',
                          lg: '16px'
                        },
                        width: '100%',
                        textTransform: 'none'
                      }}
                    >
                      Show irradiance map
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setGeoTiffData({})}
                      sx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        backgroundColor: '#0062cc',
                        fontFamily: 'Segoe UI',
                        color: 'white',
                        border: 'none',
                        borderRadius: '20px',
                        fontSize: {
                          xs: '11px',
                          sm: '15px',
                          md: '16px',
                          lg: '16px'
                        },
                        width: '100%',
                        textTransform: 'none'
                      }}
                    >
                      Hide irradiance map
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    onClick={handlePlacePanelButtonClick}
                    disabled={
                      polygonPaths.length === 0 ||
                      polygonPaths[0].length === 0 ||
                      polygonPaths[0].length === 1 ||
                      polygonPaths[0].length === 2 ||
                      ridgeLinePoints.length !== 2
                    }
                    color="primary"
                    sx={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      backgroundColor: '#0062cc',
                      fontFamily: 'Segoe UI',
                      color: 'white',
                      border: 'none',
                      borderRadius: '20px',
                      fontSize: {
                        xs: '11px',
                        sm: '15px',
                        md: '16px',
                        lg: '16px'
                      },
                      width: '100%',
                      textTransform: 'none'
                    }}
                  >
                    Place panels
                  </Button>
                </>
              )}
            </Box>
            {searchedLocationMarker && (
              <Marker
                position={searchedLocationMarker}
                icon={searchedLocationIcon}
                draggable={false}
              />
            )}
            <Marker position={initialCenter} />
            {markers.length > 0 &&
              selectAreaClicked &&
              markers.map((marker, index) => (
                <Marker
                  key={index}
                  position={{ lat: marker.lat, lng: marker.lng }}
                  draggable={true}
                  onDragEnd={(e: AnyRecord) => {
                    const newMarkers = [...markers];
                    newMarkers[index] = { lat: e.latLng.lat(), lng: e.latLng.lng() };
                    setMarkers(newMarkers);
                  }}
                />
              ))}
            {ridgeLinePoints.length >= 1 && (
              <Polyline
                path={ridgeLinePoints}
                options={{
                  strokeColor: 'blue',
                  strokeOpacity: 1.0,
                  strokeWeight: 2
                }}
              />
            )}
            {polygonPaths.length > 0 &&
              polygonPaths.map((polygonPath, index) => (
                <PolygonF
                  key={index}
                  path={polygonPath}
                  options={{
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: 'red',
                    fillOpacity: 0.35
                  }}
                />
              ))}
            {markers.length >= 1 && (
              <Polyline
                path={markers}
                options={{
                  strokeColor: '#FF0000',
                  strokeOpacity: 1.0,
                  strokeWeight: 2
                }}
              />
            )}
            {/* @ts-expect-error TODO fix this type later */}
            {map && map.paths ? map.paths.map((ele) => ele) : null}
          </GoogleMap>
        )}
      </div>
    </div>
  );
};
