import { useFetch } from '@hooks/useFetch';

import { FetchResource } from '@app-types/api';
import { SolarComponentType } from '@app-types/general';
import { getEndpoint } from '@utils/api';

export const useGetComponentTypes = (): {
  getComponentTypes: FetchResource<void, Array<SolarComponentType>>;
} => {
  const fetch = useFetch<Array<SolarComponentType>>();

  return {
    getComponentTypes: {
      data: fetch[0],
      status: fetch[1],
      fetch: (_, options = {}) => {
        fetch[2](
          {
            method: 'get',
            url: getEndpoint({
              path: '/component/get-component-types'
            })
          },
          options
        );
      },
      reset: fetch[3]
    }
  };
};
