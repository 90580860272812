import  { useEffect, useState } from 'react';

import '../design-popup/index.css';
import { useAddSolarDesign } from '@api/design/useAddSolarDesign';

export const AddDesign = ({ design, onClose, selectedComponentIds = [] }) => {
  const isEditMode = design && design.designType !== undefined;
  const [availableComponents, setAvailableComponents] = useState(design || []);
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [designType, setDesignType] = useState(design ? design.designType : 'New Design');
  const [selectedAvailableComponentIndex, setSelectedAvailableComponentIndex] = useState(null);
  const [selectedSelectedComponentIndex, setSelectedSelectedComponentIndex] = useState(null);
  const [designComponentId, setDesignComponentId] = useState(null); // To hold the selected component ID

  useEffect(() => {
    if (selectedComponentIds.length > 0) {
      const initialSelectedComponents = availableComponents.filter((component) =>
        selectedComponentIds.includes(component.id)
      );
      setSelectedComponents(initialSelectedComponents);
      setAvailableComponents(
        availableComponents.filter((component) => !selectedComponentIds.includes(component.id))
      );
    }
  }, [selectedComponentIds, availableComponents]);

  // Select an available component by index
  const handleSelectAvailableComponent = (index) => {
    setSelectedAvailableComponentIndex(index);
    setSelectedSelectedComponentIndex(null); // Clear selected from the other list
    setDesignComponentId(availableComponents[index].id); // Set the component ID
  };

  // Select a selected component by index
  const handleSelectSelectedComponent = (index) => {
    setSelectedSelectedComponentIndex(index);
    setSelectedAvailableComponentIndex(null); // Clear selected from the other list
    setDesignComponentId(selectedComponents[index].id); // Set the component ID
  };

  // Move a component from available to selected
  const handleMoveToSelected = () => {
    if (selectedAvailableComponentIndex !== null) {
      const componentToMove = availableComponents[selectedAvailableComponentIndex];
      setAvailableComponents(
        availableComponents.filter((_, i) => i !== selectedAvailableComponentIndex)
      );
      setSelectedComponents([...selectedComponents, componentToMove]);
      setSelectedAvailableComponentIndex(null);
      setDesignComponentId(null); // Reset component ID after move
    }
  };

  // Move a component from selected back to available
  const handleMoveToAvailable = () => {
    if (selectedSelectedComponentIndex !== null) {
      const componentToMove = selectedComponents[selectedSelectedComponentIndex];
      setSelectedComponents(
        selectedComponents.filter((_, i) => i !== selectedSelectedComponentIndex)
      );
      setAvailableComponents([...availableComponents, componentToMove]);
      setSelectedSelectedComponentIndex(null);
      setDesignComponentId(null); // Reset component ID after move
    }
  };

const {addSolarDesign} = useAddSolarDesign()

  const handleSave = async () => {
    const designData = {
      designType: designType || 'New Design',
      designComponentIds: selectedComponents.map((component) => component.id) // Get component IDs
    };

    console.log('Design Data to Save:', designData);
    // Log individual designComponentIds for detailed inspection
    designData.designComponentIds.forEach((id, index) => {
      console.log(`Component ID ${index + 1}:`, id);
    });

    addSolarDesign.fetch(designData, {
      onAfterSuccess: () => {
        alert('Design saved successfully!');
        onClose();
      }
    });
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h2 className="text-center">
            {isEditMode
              ? 'Please modify the information of the selected design type'
              : 'Please add a new design type'}
          </h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="popup-body">
          <label htmlFor="designType">Design Type:</label>
          <input
            type="text"
            id="designType"
            value={designType}
            className="design-type-input"
            placeholder={!isEditMode ? 'Enter design name' : ''}
            onChange={(e) => setDesignType(e.target.value)}
          />
          <div className="components-section">
            <div className="components-list-container">
              <h3>Available component types</h3>
              <ul className="components-list">
                {availableComponents.length > 0 ? (
                  availableComponents.map((component, index) => (
                    <li
                      key={component.id}
                      onClick={() => handleSelectAvailableComponent(index)}
                      className={selectedAvailableComponentIndex === index ? 'selected' : ''}
                    >
                      {component.componentsDescription}
                    </li>
                  ))
                ) : (
                  <li>No components available</li>
                )}
              </ul>
            </div>
            <div className="buttons-section">
              <button
                className="transfer-button"
                onClick={handleMoveToSelected}
                disabled={selectedAvailableComponentIndex === null}
              >
                &gt;
              </button>
              <button
                className="transfer-button"
                onClick={handleMoveToAvailable}
                disabled={selectedSelectedComponentIndex === null}
              >
                &lt;
              </button>
            </div>
            <div className="components-list-container">
              <h3>Selected component types</h3>
              <ul className="components-list">
                {selectedComponents.length > 0 ? (
                  selectedComponents.map((component, index) => (
                    <li
                      key={component.id}
                      onClick={() => handleSelectSelectedComponent(index)}
                      className={selectedSelectedComponentIndex === index ? 'selected' : ''}
                    >
                      {component.componentsDescription}
                    </li>
                  ))
                ) : (
                  <li>No components selected</li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="popup-actions">
          <button className="save-button" onClick={handleSave}>
            {isEditMode ? 'Save' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};
